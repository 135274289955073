import React from 'react'

import TextInput, { TextInputPropTypes } from './TextInput'

function DateInput({ min, max, ...rest }) {
  return (
    <TextInput
      flex="1"
      {...rest}
      type="date"
      inputProps={{
        pattern: '\\d{4}-\\d{2}-\\d{2}',
        min: min || '',
        max: max || '',
      }}
    />
  )
}

DateInput.propTypes = TextInputPropTypes

DateInput.defaultProps = {
  ...TextInput.defaultProps,
  //   type: 'date',
}

export default DateInput
