import { Box, Button, Tab, Tabs } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import React, { useState, useEffect, useMemo } from 'react'
import useStateValue from '../../hooks/useStateValue'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import TabPanel from '../Reusable/TabPanel'
import Locations from './Locations'
import Products from './Products'
import CompanyDetails from './CompanyDetails'
import routes from '../../routes/route_contants'
import Tanks from './Tanks'
import AddLocationModal from '../Location/AddLocationModal'
import withPaginationLocal from '../../HOC/withPaginationLocal'
import tankAPI from '../../api/tank'
import TeamMembersInvitation from './TeamMembers/TeamMembersInvitation'
import TeamMembersInvitationModal from './TeamMembers/TeamMembersInvitationModal'
import productAPI from '../../api/product'
import contactsAPI from '../../api/contacts'
import Contacts from './Contacts/Contacts'

function MyCompany() {
  const [currentTab, setCurrentTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenInvitation, setIsOpenInvitation] = useState(false)
  const [locationReload, setLocationReload] = useState(false)

  const { location } = useHistory()

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const toggleModalInvitation = () => {
    setIsOpenInvitation(!isOpenInvitation)
  }

  const onLocationAdded = (res, type) => {
    setLocationReload(true)
    setIsOpen(false)
  }

  const PaginatedProducts = withPaginationLocal(productAPI.list, {
    search: '',
    limit: 12,
  })(Products)

  const [buttonClicked, setButtonClicked] = useState(false)
  const history = useHistory()
  const TABS = [
    {
      label: 'My Account',
      Component: () => (
        <CompanyDetails
          editDetails={buttonClicked}
          onEditDetailsFinished={() => setButtonClicked(false)}
        />
      ),
      hash: '#my-account',
      button: {
        label: 'Edit details',

        onClick: () => history.push(routes.EDITDETAILS_TENANT),
      },
    },
    {
      label: 'Locations',
      hash: '#locations',
      Component: Locations,
      button: { label: 'Add location', onClick: () => toggleModal() },
    },
    {
      label: 'Products',
      hash: '#products',
      Component: PaginatedProducts,
      button: {
        label: 'Add Product',
        onClick: () => history.push(routes.PRODUCT_ADD),
      },
    },
    {
      label: 'Tanks',
      hash: '#tanks',
      Component: withPaginationLocal(tankAPI.list, {
        tankType: 'tenant_tank',
      })(Tanks),
      button: {
        label: 'Add Tank',
        onClick: () => history.push(routes.ADD_TANK),
      },
    },
    {
      label: 'CargoCheck Users',
      hash: '#cargocheck-users',
      Component: TeamMembersInvitation,
      button: {
        label: 'Add User',
        onClick: () => toggleModalInvitation(),
      },
      additionalButton: {
        label: 'See Invited Users',
        hash: '#details',
        onClick: () => history.push(routes.TEAM_MEMBERS_INVITATION),
      },
    },
    {
      label: 'Operational Contacts',
      hash: '#operational-contacts',
      Component: useMemo(
        () => withPaginationLocal(contactsAPI.getContacts)(Contacts),
        []
      ),
      button: {
        label: 'Add Contact',
        onClick: () => {
          return history.push({
            pathname: `${routes.CREATE_CONTACT}`,
            state: { mode: 'create' },
          })
        },
      },
    },
  ]

  const [state] = useStateValue()

  useEffect(() => {
    const currentTab = TABS.findIndex(e => e.hash === window.location.hash)
    if (currentTab !== -1) setCurrentTab(currentTab)
  }, [window.location.hash])

  useEffect(() => {
    const currentTab = TABS.findIndex(e =>
      window.location.hash.startsWith(e.hash)
    )
    if (currentTab !== -1) setCurrentTab(currentTab)
  }, [])

  const inviteData = data => {
    if (data.error != null) {
      toast.error(data?.error || 'Error')
    } else {
      toast.success('Invitation sent')
    }
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle
            title={state.user?.user_info?.tenant?.tenant_name || 'My Account'}
          />
          <Box className="flex-row">
            {TABS[currentTab].additionalButton && (
              <Box mr={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={TABS[currentTab].additionalButton?.onClick}
                >
                  {TABS[currentTab].additionalButton?.label}
                </Button>
              </Box>
            )}
            {TABS[currentTab].button && (
              <Button
                variant="contained"
                color="primary"
                onClick={TABS[currentTab].button?.onClick}
              >
                {TABS[currentTab].button?.label}
              </Button>
            )}
          </Box>
        </PageHeading>
        <Box mt={2}>
          <PageHeading alignItems="center">
            <Tabs
              value={currentTab}
              onChange={(e, v) => setCurrentTab(v)}
              indicatorColor="white"
            >
              {TABS.map((tab, index) => (
                <Tab
                  key={tab.label}
                  onClick={() => history.push(tab.hash)}
                  label={tab.label}
                  style={
                    index === currentTab
                      ? {
                          color: 'white',
                          backgroundColor: '#3298FF',
                          height: '100%',
                          borderRadius: '30px',
                        }
                      : {}
                  }
                />
              ))}
            </Tabs>

            <Box mr={2}>
              <Button
                onClick={() => {}}
                className="hidden"
                ref={null}
                variant="contained"
                color="primary"
              >
                Download QR
              </Button>
            </Box>
          </PageHeading>
        </Box>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        {TABS.map((tab, index) => {
          return (
            <TabPanel
              key={`PANEL-${tab.label}`}
              value={currentTab}
              index={index}
            >
              <tab.Component locationReload={locationReload} />
            </TabPanel>
          )
        })}
      </Scrollable>

      <AddLocationModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        onDone={onLocationAdded}
      />
      <TeamMembersInvitationModal
        isOpen={isOpenInvitation}
        toggleModal={toggleModalInvitation}
        sendData={inviteData}
      />
    </Container>
  )
}

export default MyCompany
