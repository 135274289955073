import {
  Box,
  Button,
  Divider,
  makeStyles,
  Select,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import routes from '../../routes/route_contants'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import TextInput from '../Reusable/Widgets/TextInput'
import SelectInput from '../Reusable/Widgets/SelectInput'
import Steps, { steps } from './Steps'
import AutoCompleteInput from '../Reusable/Widgets/AutoCompleteInput'
import useForm from '../../hooks/useForm'
import DateInput from '../Reusable/Widgets/DateInput'
import CheckBoxInput from '../Reusable/Widgets/CheckBoxInput'
import { modifyFields } from './steps/utils'
import useStateValue from '../../hooks/useStateValue'
import Loading from '../Loading'
import productAPI from '../../api/product'
import Modal from '../Modal/index'
import BulkUploadField from '../fieldsUpdating'
import chemicalAPI from '../../api/chemical'
import useAPILocal from '../../hooks/useAPILocal'

const productFields = step => {
  return (
    step === 'all' ? steps.map(e => e.data).flat(1) : steps[step].data
  ).reduce((acc, e) => {
    acc = [...acc, ...e.children]
    return acc
  }, [])
}

const validationSchema = currentStep => {
  return productFields(currentStep).reduce((acc, e) => {
    if (e.validations) acc[e.name] = e.validations
    return acc
  }, {})
}

const initValues = productFields(0).reduce((acc, e) => {
  acc[e.name] = e.initial
  return acc
}, {})

const InputTypes = {
  text: { Component: TextInput },
  select: {
    Component: SelectInput,
    props: {
      SelectComponent: withStyles({ icon: { marginRight: 13 } })(Select),
    },
  },
  check: { Component: CheckBoxInput },
  autocomplete: { Component: AutoCompleteInput },
  date: { Component: DateInput },
}

function ChemicalAdd(props) {
  const mode = props.location?.state?.mode || 'add'
  const oldData = props.location?.state?.details || {}
  const history = useHistory()
  const [fields, setFields] = useState([{ property: '', value: '' }])
  const [extraFields, setExtraFields] = useState([])
  const addField = () => {
    setFields([...fields, { property: '', value: '' }])
  }
  const [fileUploadStatus, setFileUploadStatus] = useState('idle')
  const [isOpen, setIsOpen] = useState(false)
  const [currentFile, setCurrentFile] = useState('')
  const toggleModal = () => {
    setIsOpen(true)
  }
  const onClose = () => {
    setIsOpen(false)
    setCurrentFile('')
    setFileUploadStatus('idle')
  }
  const mimes = {
    csv: 'application/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
  }
  const handleFileChange = file => {
    const ext = file.name.match(/[^\\]*\.(\w+)$/)[1]
    // setFileExtension(ext)
    if (!(ext in mimes)) {
      alert('Upload a file with acceptable formats (.xlsx, .csv)')
      return
    }
    setCurrentFile(file)
  }

  const uploadBulkProducts = async () => {
    try {
      setFileUploadStatus('pending')

      productAPI
        .bulkCreate(currentFile)
        .then(response => {
          if (response.data.status === 'error') setFileUploadStatus('rejected')
          else setFileUploadStatus('resolved')
        })
        .catch(err => {
          console.log(err)
        })
    } catch (err) {
      setFileUploadStatus('rejected')
    }
  }

  const [currentStep, setCurrentStep] = useState(0)
  const [
    {
      user: { user_info },
    },
  ] = useStateValue()
  const createChemical = useAPILocal(
    mode === 'update' ? chemicalAPI.update : chemicalAPI.newCreate
  )

  const goToNext = () => {
    if (currentStep < (steps.count - 1)) setCurrentStep(prev => prev + 1)
  }

  const { values, errors, handleOnChange, handleSubmit } = useForm(
    { ...initValues, ...oldData },
    // validationSchema(currentStep),
    validationSchema(0),
    result => {
      if (currentStep < (steps.count - 1)) goToNext()
      else if (mode === 'update') {
        createChemical
          .request(result.id, {
            ...modifyFields(result),
          })
          .then(data => {
            if (data.status <= 201) {
              toast.success('Chemical updated successfully')
              history.push(routes.CHEMICAL_LIST)
            }
          })
          .catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
          })
      } else {
        createChemical
          .request({
            ...modifyFields(result),
            extra_fields: result.extra_fields,
          })
          .then(data => {
            if (data.status <= 201) {
              toast.success('Chemical created successfully')
              history.push(routes.CHEMICAL_LIST)
            }
          })
          .catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
          })
      }
    }
  )

  const additionalProps = name => {
    const addProps = {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
    if (name === 'name') addProps.clearOnBlur = false
    return addProps
  }

  const bulKModal = (
    <Modal visible={isOpen} onClose={onClose}>
      <>
        {(fileUploadStatus === 'idle' || fileUploadStatus === 'rejected') && (
          <div>
            <label className="file">
              <input
                type="file"
                id="file"
                accept={`${mimes.csv}`}
                aria-label="File browser example"
                onChange={e => handleFileChange(e.target.files[0])}
              />
              <span className="file-custom"></span>
            </label>
            {currentFile && (
              <button
                type="button"
                disabled={fileUploadStatus === 'pending'}
                onClick={uploadBulkProducts}
                className="btn-primary btn-block my-1"
              >
                Upload
                {fileUploadStatus === 'pending' && (
                  <CircularProgress
                    style={{ marginLeft: 10 }}
                    size={15}
                    mode="indeterminate"
                  />
                )}
              </button>
            )}
          </div>
        )}
        <BulkUploadField type="product" />
        {fileUploadStatus === 'rejected' && (
          <div style={{ textAlign: 'center' }}>
            <p id="alert-dialog-slide-description" style={{ color: 'red' }}>
              Something went wrong ! Try again
            </p>
          </div>
        )}
        {fileUploadStatus === 'pending' && (
          <div className="dialog-success-message" style={{ width: '100%' }}>
            <div>
              <CircularProgress thickness={4} size="100px" />
            </div>
            <div>Bulk Product details are being uploaded</div>
          </div>
        )}
        {fileUploadStatus === 'resolved' && (
          <div
            className="dialog-success-message"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'cente',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div>
              <CheckCircleOutlineIcon
                style={{ fontSize: '48px', color: 'mediumseagreen' }}
              />
            </div>
            <br />
            <div>Data Updated Succesfully </div>
          </div>
        )}
      </>
    </Modal>
  )

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle
            title={mode === 'add' ? 'Add Chemical' : 'Edit Chemical'}
            backButton
          />
          {/* <Button variant="outlined" color="primary" onClick={toggleModal}>
            Bulk Upload
          </Button> */}
        </PageHeading>
      </Fixed>
      {bulKModal}
      <Scrollable mt={2} style={{ height: '100%' }}>
        <Box display="flex" height="100%">
          <Box overflow="auto" height="100%">
            <Steps onChange={setCurrentStep} current={currentStep} />
          </Box>
          <Box mx={6} px={2}>
            <Divider orientation="vertical" />
          </Box>
          <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
            {createChemical.isPending ? (
              <Loading />
            ) : (
              steps[0].data.map((row, index) => {
                if (!row.visible(values)) {
                  return null
                }
                const key = `${row.key}-${index}`
                return (
                  <Box key={key} display="flex" className="form-row">
                    {row.children.map(child => {
                      const Type = InputTypes[child.type]
                      return (
                        <>
                          <Type.Component
                            key={child.name}
                            flex="1"
                            {...child}
                            {...additionalProps(child.name)}
                            {...Type.props}
                          />
                        </>
                      )
                    })}
                  </Box>
                )
              })
            )}
          </Box>
        </Box>
      </Scrollable>
      <Fixed>
        <Box py={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {currentStep < (steps.count - 1) ? 'Next' : 'Finish'}
          </Button>
        </Box>
      </Fixed>
    </Container>
  )
}

export default ChemicalAdd
