import React, { useState, useEffect } from 'react'
import { Box, Button, Divider, makeStyles, Tab, Tabs } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useForm as useReactForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Container from '../../Reusable/Container'
import Scrollable from '../../Reusable/Scrollable'
import TextInput from '../../Reusable/Widgets/TextInput'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import CheckBoxInput from '../../Reusable/Widgets/CheckBoxInput'
import useForm from '../../../hooks/useForm'
import Details from './Details'
import Questions from './Questions'
import DetailCard from '../DetailCard'
import useAPILocal from '../../../hooks/useAPILocal'
import checklistAPI from '../../../api/checklist'
import tenantAPI from '../../../api/tenant'
import tankAPI from '../../../api/tank'
import productAPI from '../../../api/product'
import locationAPI from '../../../api/location'
import Loading from '../../Loading'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
}))

const StepComponent = ({ index, label }) => (
  <Box display="flex">
    <Box mr={2}>{index + 1}</Box> {label}
  </Box>
)

const TABS = [
  { label: 'Checklist Details', data: Details },
  { label: 'Questions', data: Questions },
]

const sendPathString = arr => {
  const path = `questions${arr.reduce((prev, curr, currentIndex) => {
    prev = _.cloneDeep(prev)
    prev += currentIndex === 0 ? `[${curr}]` : `.subQuestions[${curr}]`
    return prev
  }, '')}`
  return path
}

const renderCheckList = ({ index, indexArr, questions, reverse }) => {
  const pathString = sendPathString([...indexArr, index])
  const index_ = index
  if (_.get(questions, `${pathString}.answerType`) === 'YES_NO') {
    if (reverse) _.set(questions, `${pathString}.answers`, ['YES', 'NO'])
    else _.set(questions, `${pathString}.answers`, [])
  }

  if (_.get(questions, `${pathString}.subQuestions`).length > 0)
    renderCheckList({
      index: 0,
      indexArr: [...indexArr, index_],
      questions,
      reverse,
    })
}

const CreateChecklist = () => {
  const classes = useStyles()
  const [currentStep, setCurrentStep] = useState(0)
  const history = useHistory()
  const location = useLocation()
  const createChecklist = useAPILocal(checklistAPI.createChecklist)
  const updateChecklist = useAPILocal(checklistAPI.updateChecklist)
  const singleChecklist = useAPILocal(checklistAPI.singleChecklist)

  const init = {
    active: true,
    required: false,
    roles: [],
    states: [],
    name: '',
    tenants: { show: false, all: false, data: [] },
    products: { show: false, all: false, data: [] },
    tanks: { show: false, all: false, data: [] },
    locations: { show: false, all: false, data: [] },
    questions: [],
  }

  const methods = useReactForm({
    defaultValues: {
      questions: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const validationSchema = {
    name: ['required'],
    states: ['required'],
    roles: ['required'],
  }

  const {
    errors,
    setErrors,
    handleOnChange,
    values,
    handleSubmit,
    validate,
    setValues,
  } = useForm(init, validationSchema, async result => {
    result = _.cloneDeep(result)
    if (Object.keys(methods.formState.errors).length > 0) return
    result.questions = methods.watch('questions')
    result.questions = result.questions.map(item => {
      item = _.cloneDeep(item)
      delete item.createdAt
      return item
    })
    result.questions.forEach((item, index) => {
      renderCheckList({
        index,
        indexArr: [],
        questions: result,
      })
    })
    delete result.createdAt
    delete result.owner
    delete result.answeredAt
    delete result.id
    delete result.associations
    const arr = ['tenants', 'products', 'tanks', 'locations']
    arr.forEach(item => {
      if (result[item].all) {
        result[item] = []
      } else if (result[item].data.length > 0) {
        result[item] = result[item].data.map(item => item.id)
      } else {
        delete result[item]
      }
    })
    if (location.state)
      updateChecklist.request(location.state, result)
        .then(res => {
          toast.success('Checklist updated successfully')
          if (res?.data?.id) history.push(`/checklist`)
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
    else
      createChecklist.request(result)
        .then(res => {
          toast.success('Checklist created successfully')
          if (res?.data?.id) history.push(`/checklist`)
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
  })

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  useEffect(() => {
    if (location.state)
      singleChecklist.request(location.state).then(res => {
        const temp = _.cloneDeep(res.data)
        if (
          res?.data?.associations?.some(
            item => item.entityType === 'TENANT' && item.entityId === '*'
          )
        ) {
          temp.tenants = { show: true, all: true, data: [] }
        } else if (
          res?.data?.associations?.filter(item => item.entityType === 'TENANT')
            ?.length > 0
        ) {
          temp.tenants = {
            show: true,
            all: false,
            data: res?.data?.associations
              ?.filter(item => item.entityType === 'TENANT')
              ?.map(item => ({ id: item.entityId, name: item.entityName })),
          }
        } else {
          temp.tenants = { show: false, all: false, data: [] }
        }

        if (
          res?.data?.associations?.some(
            item => item.entityType === 'PRODUCT' && item.entityId === '*'
          )
        ) {
          temp.products = { show: true, all: true, data: [] }
        } else if (
          res?.data?.associations?.filter(item => item.entityType === 'PRODUCT')
            ?.length > 0
        ) {
          temp.products = {
            show: true,
            all: false,
            data: res?.data?.associations
              ?.filter(item => item.entityType === 'PRODUCT')
              ?.map(item => ({ id: item.entityId, name: item.entityName })),
          }
        } else {
          temp.products = { show: false, all: false, data: [] }
        }

        if (
          res?.data?.associations?.some(
            item => item.entityType === 'TANK' && item.entityId === '*'
          )
        ) {
          temp.tanks = { show: true, all: true, data: [] }
        } else if (
          res?.data?.associations?.filter(item => item.entityType === 'TANK')
            ?.length > 0
        ) {
          temp.tanks = {
            show: true,
            all: false,
            data: res?.data?.associations
              ?.filter(item => item.entityType === 'TANK')
              ?.map(item => ({ id: item.entityId, name: item.entityName })),
          }
        } else {
          temp.tanks = { show: false, all: false, data: [] }
        }

        if (
          res?.data?.associations?.some(
            item => item.entityType === 'LOCATION' && item.entityId === '*'
          )
        ) {
          temp.locations = { show: true, all: true, data: [] }
        } else if (
          res?.data?.associations?.filter(
            item => item.entityType === 'LOCATION'
          )?.length > 0
        ) {
          temp.locations = {
            show: true,
            all: false,
            data: res?.data?.associations
              ?.filter(item => item.entityType === 'LOCATION')
              ?.map(item => ({ id: item.entityId, name: item.entityName })),
          }
        } else {
          temp.locations = { show: false, all: false, data: [] }
        }
        setValues(temp)
        const question = { questions: _.cloneDeep(res?.data?.questions) }
        question.questions.forEach((item, index) => {
          renderCheckList({
            index,
            indexArr: [],
            questions: question,
            reverse: true,
          })
        })

        methods.reset(question)
      })
  }, [])

  return (
    <Container pt={3} px={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle
            backButton
            title={location.state ? 'Edit Checklist' : 'Create Checklist'}
          />
        </PageHeading>
      </Fixed>
      {singleChecklist.isPending ||
      createChecklist.isPending ||
      updateChecklist.isPending ? (
        <Loading />
      ) : (
        <Scrollable mt={2} style={{ height: '100%' }}>
          <Box display="flex" height="100%">
            <Box overflow="auto" height="100%" pl={6}>
              <Tabs
                orientation="vertical"
                TabIndicatorProps={{ style: { display: 'none' } }}
                value={currentStep}
                onChange={(e, v) => setCurrentStep(v)}
              >
                {TABS.map((item, idx) => (
                  <Tab
                    className={classes.tabs}
                    wrapped={false}
                    key={item?.label}
                    label={<StepComponent index={idx} label={item?.label} />}
                  />
                ))}
              </Tabs>
            </Box>
            <Box mx={6} px={2}>
              <Divider orientation="vertical" style={{ height: '90%' }} />
            </Box>
            <Box
              flex="1"
              mr={2}
              px={1}
              pb={2}
              overflow="auto"
              height="100%"
              justifyContent="space-between"
            >
              {currentStep === 0 && (
                <Box display="flex">
                  <Box flex="80%" height="100%">
                    <Box display="flex" className="form-row">
                      <TextInput
                        flex="0.5"
                        label="Checklist Name"
                        name="name"
                        {...additionalProps('name')}
                      />
                      {/* <SelectInput
                        flex="0.5"
                        name="userRole"
                        label="Type"
                        options={[
                          { label: 'Loading', value: 'LOADING' },
                          { label: 'Unloading', value: 'UNLOADING' },
                        ]}
                        value={9}
                        {...additionalProps('type')}
                      /> */}
                    </Box>
                    <Box display="flex" className="form-col" name="states">
                      <CheckBoxInput
                        label="Pre-Shipment"
                        value={values.states.find(
                          item => item === 'PRE_SHIPMENT'
                        )}
                        onChange={e => {
                          const val = 'PRE_SHIPMENT'
                          handleOnChange({
                            name: 'states',
                            value: e
                              ? values.states.concat(val)
                              : values.states.filter(item => item !== val),
                          })
                        }}
                        checklist
                        showLabel="Steps"
                      />
                      <CheckBoxInput
                        label="Arrive To Load"
                        value={values.states.find(
                          item => item === 'ARRIVE_TO_LOAD'
                        )}
                        onChange={e => {
                          const val = 'ARRIVE_TO_LOAD'
                          handleOnChange({
                            name: 'states',
                            value: e
                              ? values.states.concat(val)
                              : values.states.filter(item => item !== val),
                          })
                        }}
                        showLabel
                        checklist
                      />
                      <CheckBoxInput
                        label="In Transit"
                        value={values.states.find(
                          item => item === 'IN_TRANSIT'
                        )}
                        onChange={e => {
                          const val = 'IN_TRANSIT'
                          handleOnChange({
                            name: 'states',
                            value: e
                              ? values.states.concat(val)
                              : values.states.filter(item => item !== val),
                          })
                        }}
                        showLabel
                        checklist
                      />
                      <CheckBoxInput
                        label="Arrive To Unload"
                        value={values.states.find(
                          item => item === 'ARRIVE_TO_UNLOAD'
                        )}
                        onChange={e => {
                          const val = 'ARRIVE_TO_UNLOAD'
                          handleOnChange({
                            name: 'states',
                            value: e
                              ? values.states.concat(val)
                              : values.states.filter(item => item !== val),
                          })
                        }}
                        showLabel
                        checklist
                      />
                      <CheckBoxInput
                        label="Delivered"
                        value={values.states.find(item => item === 'DELIVERED')}
                        onChange={e => {
                          const val = 'DELIVERED'
                          handleOnChange({
                            name: 'states',
                            value: e
                              ? values.states.concat(val)
                              : values.states.filter(item => item !== val),
                          })
                        }}
                        showLabel
                        checklist
                      />
                    </Box>
                    {errors?.states && (
                      <div className="input-error">{errors?.states}</div>
                    )}
                    <Box display="flex" className="form-col" name="roles">
                      <CheckBoxInput
                        label="Driver"
                        value={values.roles.find(item => item === 'DRIVER')}
                        onChange={e => {
                          const val = 'DRIVER'
                          handleOnChange({
                            name: 'roles',
                            value: e
                              ? values.roles.concat(val)
                              : values.roles.filter(item => item !== val),
                          })
                        }}
                        checklist
                        showLabel="Roles"
                      />
                      <CheckBoxInput
                        label="Operator"
                        value={values.roles.find(item => item === 'OPERATOR')}
                        onChange={e => {
                          const val = 'OPERATOR'
                          handleOnChange({
                            name: 'roles',
                            value: e
                              ? values.roles.concat(val)
                              : values.roles.filter(item => item !== val),
                          })
                        }}
                        showLabel
                        checklist
                      />
                    </Box>
                    {errors?.roles && (
                      <div className="input-error">{errors?.roles}</div>
                    )}
                    <Box display="flex" className="form-col">
                      <CheckBoxInput
                        label="Required"
                        {...additionalProps('required')}
                        checklist
                        showLabel="Want to make it compulsory ?"
                      />
                    </Box>
                    <Box
                      display="flex"
                      className="form-col"
                      style={{ width: '70%', marginBottom: '20px' }}
                      name="products"
                    >
                      <CheckBoxInput
                        label="Product"
                        value={!!values.products.show}
                        onChange={e => {
                          handleOnChange({
                            name: 'products',
                            value: e
                              ? {
                                  show: true,
                                  all: true,
                                  data: [],
                                }
                              : {
                                  show: false,
                                  all: false,
                                  data: [],
                                },
                          })
                        }}
                        showLabel="Assign To"
                        checklist
                      />
                      <CheckBoxInput
                        label="Tenant"
                        value={!!values.tenants.show}
                        onChange={e => {
                          handleOnChange({
                            name: 'tenants',
                            value: e
                              ? {
                                  show: true,
                                  all: true,
                                  data: [],
                                }
                              : {
                                  show: false,
                                  all: false,
                                  data: [],
                                },
                          })
                        }}
                        showLabel=""
                        checklist
                      />
                      <CheckBoxInput
                        label="Tank"
                        value={!!values.tanks.show}
                        onChange={e => {
                          handleOnChange({
                            name: 'tanks',
                            value: e
                              ? {
                                  show: true,
                                  all: true,
                                  data: [],
                                }
                              : {
                                  show: false,
                                  all: false,
                                  data: [],
                                },
                          })
                        }}
                        showLabel=""
                        checklist
                      />
                      <CheckBoxInput
                        label="Location"
                        value={!!values.locations.show}
                        onChange={e => {
                          handleOnChange({
                            name: 'locations',
                            value: e
                              ? {
                                  show: true,
                                  all: true,
                                  data: [],
                                }
                              : {
                                  show: false,
                                  all: false,
                                  data: [],
                                },
                          })
                        }}
                        showLabel=""
                        checklist
                      />
                    </Box>
                    {errors?.assignedTo && (
                      <div className="input-error">{errors?.assignedTo}</div>
                    )}
                    {values.products.show && (
                      <DetailCard
                        type="Product"
                        rows={values?.products}
                        setRows={val => {
                          handleOnChange({
                            name: 'products',
                            value: val,
                          })
                        }}
                        idField="id"
                        nameField="name"
                        api={{
                          src: productAPI.list,
                          params: {
                            limit: 10,
                            page: 1,
                          },
                        }}
                        renderItem={option => option?.name}
                      />
                    )}
                    {values.tenants.show && (
                      <DetailCard
                        type="Tenant"
                        rows={values?.tenants}
                        setRows={val => {
                          handleOnChange({
                            name: 'tenants',
                            value: val,
                          })
                        }}
                        idField="tenant_id"
                        nameField="tenant_name"
                        api={{
                          src: tenantAPI.tenants,
                        }}
                        renderItem={option => option?.tenant_name}
                      />
                    )}
                    {values.tanks.show && (
                      <DetailCard
                        type="Tank"
                        rows={values?.tanks}
                        setRows={val => {
                          handleOnChange({
                            name: 'tanks',
                            value: val,
                          })
                        }}
                        idField="tankRegistrationNo"
                        nameField="tankRegistrationNo"
                        api={{
                          src: tankAPI.list,
                          params: {
                            limit: 10,
                            page: 1,
                            tankType: '',
                          },
                        }}
                        renderItem={option => option?.tankRegistrationNo}
                      />
                    )}
                    {values.locations.show && (
                      <DetailCard
                        type="Location"
                        rows={values?.locations}
                        setRows={val => {
                          handleOnChange({
                            name: 'locations',
                            value: val,
                          })
                        }}
                        idField="location_id"
                        nameField="location_name"
                        api={{
                          src: locationAPI.list,
                          params: {
                            limit: 12,
                            page: 0,
                          },
                        }}
                        renderItem={option => option?.location_name}
                      />
                    )}
                  </Box>
                </Box>
              )}
              {currentStep === 0 && (
                <>
                  <Fixed>
                    <Box py={1} display="flex" justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        flex={1}
                        onClick={async () => {
                          let err = validate()
                          err = _.cloneDeep(err)
                          if (
                            values.locations.show ||
                            values.products.show ||
                            values.tanks.show ||
                            values.tenants.show
                          )
                            setErrors(error => {
                              error = _.cloneDeep(error)
                              delete error.assignedTo
                              delete err.assignedTo
                              return error
                            })
                          else {
                            err.assignedTo = 'This field is required'
                            setErrors(error => {
                              error = _.cloneDeep(error)
                              error.assignedTo = 'This field is required'

                              return error
                            })
                          }

                          if (Object.keys(err).length === 0) setCurrentStep(1)
                        }}
                        // disabled={tenantInfoUpdate.isPending || !tenantInfo}
                      >
                        Next
                      </Button>
                    </Box>
                  </Fixed>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <Questions methods={methods} />
                  <Fixed>
                    <Box py={1} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async e => {
                          await methods.trigger()
                          handleSubmit(e)
                        }}
                        // disabled={tenantInfoUpdate.isPending || !tenantInfo}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </Fixed>
                </>
              )}
            </Box>
          </Box>
        </Scrollable>
      )}
    </Container>
  )
}

export default CreateChecklist
