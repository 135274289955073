import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { dateFormatPattern, formatLocation } from "../../../helpers/util";
import Loading from "../../Loading";
import Icon from "../../Icon";
import OrderDriverUpdate from "../OrderDriverUpdate";
import OrderEtaUpdate from "../OrderEtaUpdate";
import warning from "../../../assets/warning.svg";
import notice from "../../../assets/notice.svg";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "20px",
    lineHeight: "24px",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#1A1A1A"
  },
  content1: {
    fontSize: "15px",
    lineHeight: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#636363"
  },
  content2: {
    fontSize: "17px",
    lineHeight: "20px",
    fontFamily: "Lato",
    fontWeight: 500,
    color: "#1A1A1A",
    paddingTop: "6px"
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  warningBox: {
    width: '90%',
    height: '48px',
    backgroundColor: "#FBE6E5",
    paddingLeft: '25px',
    display: 'flex',
    alignItems: "center",
    borderRadius: '7px',
    marginBottom: '10px',
    color: '#D15D4A',
    fontSize: "15px",
    lineHeight: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
  },
  noticeBox: {
    width: '90%',
    height: '48px',
    backgroundColor: "#E6EFFA",
    paddingLeft: '25px',
    display: 'flex',
    alignItems: "center",
    borderRadius: '7px',
    marginBottom: '20px',
    fontSize: "15px",
    lineHeight: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));

const Partner = ({ heading, name, image, location }) => {
  return (
    <div>
      <Box pb={1} variant="h6" fontWeight="fontWeightBold">
        {heading}
      </Box>
      <Box display="flex">
        <Avatar variant="square" src={image} />
        <Box pl={2}>
          <Typography className="font-bold" color="primary" variant="subtitle1">
            {name}
          </Typography>
          <Typography variant="caption">{location}</Typography>
        </Box>
      </Box>
    </div>
  );
};

function getSteps() {
  return [
    "Pre Shipment",
    "Arrived to Load",
    "Transit",
    "Arrived to unload",
    "Delivered"
  ];
}

const ordered_steps = [
  "pre_shipment",
  "arrived_to_load",
  "transit",
  "arrived_to_unload",
  "delivered"
];

const ordered_steps_map = {
  pre_shipment: "Pre Shipment",
  arrived_to_load: "Arrived to Load",
  transit: "Transit",
  arrived_to_unload: "Arrived to unload",
  delivered: "Delivered"
};
const formatDate = dateStr => {
  if (!dateStr) {
    return "";
  }
  const dateObj = new Date(dateStr).toDateString();
  return dateObj;
};

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Thu, 09 Sep 2021, 12:30 PM`;
    case 1:
      return "";
    case 2:
      return ``;
    case 3:
      return ``;
    case 4:
      return ``;
    default:
      return "";
  }
}

function OrderSummary({ order, loading }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [timeline, setTimeline] = useState(null);
  const [isDriverUpdateModal, setIsDriverUpdateModal] = useState(false);
  const [isEtaUpdateModal, setIsEtaUpdateModal] = useState(false);
  const steps = getSteps();

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    if (order?.timeline) {
      setTimeline(() => order.timeline);
    }
  }, [order]);

  useEffect(() => {
    if (order?.status === ordered_steps[0]) setActiveStep(1);
    else if (order?.status === ordered_steps[1]) setActiveStep(2);
    else if (order?.status === ordered_steps[2]) setActiveStep(3);
    else if (order?.status === ordered_steps[3]) setActiveStep(4);

    if (timeline && Object.keys(timeline) !== 0) {
      if (timeline[ordered_steps[4]] !== null) {
        setActiveStep(5);
      }
    }
  }, [timeline]);

  if (loading || order === null) {
    return <Loading />;
  }

  const noticeIssues = order.issues.filter(i => i.type === 'notice')
  const warningIssues = order.issues.filter(i => i.type === 'warning')

  return (
    <div>
      <div>
        <Grid container style={{ marginTop: "25px" }}>
          <Grid item md={8}>
            {warningIssues.length > 0 && (
              <Box className={classes.warningBox}>
                <img
                  src={warning}
                  alt="warning"
                  style={{ width: '22px', height: '22px', marginRight: '5px' }}
                />
                {warningIssues.map(i => ` ${  i.text}`)}
              </Box>
            )}
            {noticeIssues.length > 0 && (
              <Box className={classes.noticeBox}>
                <img src={notice} alt="notice" style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                {noticeIssues.map(i => ` ${  i.text}`)}
              </Box>
            )}
            <Typography className={classes.heading}>BOL Details</Typography>
            <Grid container>
              <br />
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>BOL Number</Typography>
                <Typography className={classes.content2}>
                  {order.bolNo}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Receiver PO number</Typography>
                <Typography className={classes.content2}>
                  {order.customerPo}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Bill Date</Typography>
                <Typography className={classes.content2}>
                  {" "}
                  {order.billDate &&
                    moment(order.billDate).format(dateFormatPattern())}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>
                  Partner Name
                </Typography>
                <Typography className={classes.content2}>
                  {order?.receiver?.tenant_name}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Address</Typography>
                <Typography className={classes.content2}>
                  {formatLocation(order.dropLocation)}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              className={classes.heading}
              style={{ marginTop: "40px" }}
            >
              Shipment Details
            </Typography>
            <Grid container>
              <br />
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Pickup No.</Typography>
                <Typography className={classes.content2}>
                  {order?.route?.pickupNumber}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Ship Date</Typography>
                <Typography className={classes.content2}>
                  {order?.estimatedShipment &&
                    moment(order?.estimatedShipment).format(
                      dateFormatPattern()
                    )}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>
                  Estimated Arrival Date
                </Typography>
                <Typography className={classes.content2}>
                  {order?.estimatedArrival
                    ? moment(order?.estimatedArrival).format(
                      dateFormatPattern()
                    )
                    : "N/A"}
                  <Button onClick={() => setIsEtaUpdateModal(true)}>
                    <Icon
                      role="presentation"
                      name="edit"
                      color="#26365D"
                      size={18}
                    />
                  </Button>
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Driver</Typography>
                <Typography className={classes.content2}>
                  {" "}
                  <p className="">
                    {order.route?.driver?.first_name}{" "}
                    {order.route?.driver?.last_name}
                    {!order.route?.driver?.first_name &&
                      !order.route?.driver?.last_name &&
                      " - "}
                    <Button onClick={() => setIsDriverUpdateModal(true)}>
                      <Icon
                        role="presentation"
                        name="edit"
                        color="#26365D"
                        size={18}
                      />
                    </Button>
                  </p>
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>From</Typography>
                <Typography className={classes.content2}>
                  {order.shipper?.tenant_name}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Carrier</Typography>
                <Typography className={classes.content2}>
                  {order.route?.carrier?.tenant_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container></Grid>
          </Grid>
          <Grid item md={4}>
            <Typography className={classes.heading}>BOL Status</Typography>
            <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {ordered_steps.map((item, index) => (
                  <Step key={item}>
                    <StepLabel>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontFamily: "Lato,sans-serif"
                        }}
                      >
                        {ordered_steps_map[item]}
                      </Typography>
                      <Typography style={{ fontSize: "13px", color: "green" }}>
                        {formatDate(timeline?.[item]) || ""}
                      </Typography>
                    </StepLabel>
                    {/* <StepContent TransitionProps={{ unmountOnExit: false }}>
                      <Typography>
                        {formatDate(timeline?.[item]) || 'some'}
                      </Typography>
                    </StepContent> */}
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  {/* <Typography>
                    All steps completed - you&apos;re finished
                  </Typography> */}
                  {/* <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button> */}
                </Paper>
              )}
            </div>
          </Grid>
        </Grid>
        {/* <Typography variant="h5">BOL - {order.bol_no}</Typography> */}

        {/* {order?.customer_po && (
            <div>
              <Typography variant="caption">Customer PO</Typography>
              <Typography variant="body" component="p">
                {order.customer_po}
              </Typography>
            </div>
          )} */}
        {/* <div>
            <Typography variant="caption">Bill Date</Typography>
            <Typography variant="body" component="p">
              {order.created_on &&
                new Date(order.created_on).toUTCString().substr(0, 16)}
            </Typography>
          </div>
          <div>
            <Typography variant="caption">Ship Date</Typography>
            <Typography variant="body" component="p">
              {order.created_on &&
                new Date(order?.shipment?.created_on)
                  .toUTCString()
                  .substr(0, 16)}
            </Typography>
          </div> */}
        {/* <div className="orderDetailsContainer">
          <Partner
            location={order.shipper?.tenant_address}
            heading="FROM"
            name={order.shipper?.tenant_name}
            image="https://cdn2.iconfinder.com/data/icons/building-138/64/building_Factory_industrial_plant_manufacturer_icon_building_icon-512.png"
          />

          <Partner
            location={formatLocation(
              order.drop_location,
              'address city state zipcode'
            )}
            heading="SHIP TO"
            name={order.receiver.customer_name}
            image="https://icon-library.com/images/products-icon/products-icon-13.jpg"
          />

          <Partner
            location={order.shipment?.carrier?.tenant_address}
            heading="CARRIER"
            name={order.shipment?.carrier?.tenant_name}
            image="https://icon-library.com/images/order-status-icon/order-status-icon-7.jpg"
          />

          {order.shipment?.driver && (
            <div>
              <p className="heading">
                <b>DRIVER</b>
              </p>
              <p className="">
                {order.shipment?.driver?.first_name}{' '}
                {order.shipment?.driver?.last_name}
              </p>
              <p className="text-secondary">
                {order.shipment?.driver?.phone_no}
              </p>
            </div>
          )}
        </div> */}

        {isDriverUpdateModal && (
          <OrderDriverUpdate
            onDone={() => setIsDriverUpdateModal(false)}
            data={order}
            visible={isDriverUpdateModal}
            onClose={() => setIsDriverUpdateModal(false)}
          />
        )}
        {isEtaUpdateModal && (
          <OrderEtaUpdate
            onDone={() => setIsEtaUpdateModal(false)}
            order={order}
            visible={isEtaUpdateModal}
            onClose={() => setIsEtaUpdateModal(false)}
          />
        )}
      </div>
    </div>
  );
}

export default OrderSummary;
