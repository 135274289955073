import React, { useState } from 'react'
import { TableCell, Input, Modal, Button } from '@mui/material'
import { DateRangePicker } from 'react-date-range'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'

export const UnitsFilterCell = ({ filter, onFilter }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dateValue, setDateValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  return (
    <TableCell
      sx={{
        width: '100%',
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{
          position: 'absolute',
          left: '30%',
          top: '30%',
          width: 'fit-content',
          height: 'fit-content',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
        }}
      >
        <>
          <DateRangePicker
            ranges={[dateValue]}
            onChange={val => setDateValue(val.selection)}
          />
          <Button
            variant="contained"
            onClick={() => {
              onFilter({ selection: dateValue })
              setIsOpen(false)
            }}
            color="primary"
            style={{
              width: 'fit-content',
              alignSelf: 'flex-end',
              marginTop: '10px',
            }}
          >
            Done
          </Button>
        </>
      </Modal>

      <Input
        sx={{ fontSize: '14px', width: '100%' }}
        value={`${
          filter?.selection?.startDate
            ? moment(filter?.selection?.startDate).format('MM/DD/YYYY')
            : 'Start date'
        } -  ${
          filter?.selection?.endDate
            ? moment(filter?.selection?.endDate).format('MM/DD/YYYY')
            : 'End date'
        }`}
        placeholder="Filter..."
        inputProps={{
          style: { height: 'inherit' },
          min: 1,
          max: 4,
        }}
        onClick={() => {
          if (filter?.selection) {
            setDateValue(filter.selection)
          }
          setIsOpen(true)
        }}
      />
      <CloseIcon
        style={{ width: '20px', color: 'red' }}
        onClick={() => {
          onFilter(undefined)
        }}
      />
    </TableCell>
  )
}
