import React from 'react'
import ROUTES_PATH from './route_contants'
import { Tenants } from '../component'
import TenantDetails from '../component/Super/Tenants/TenantDetails'
import EditTenantDetails from '../component/Super/Tenants/EditTenantDetails'
// import InvitationDetails from '../component/Super/Invitations/InvitationDetails'
import ChemicalDetailsPage from '../component/Chemical/ChemicalDetailsPage'
import ChemicalAdd from '../component/Chemical/ChemicalAdd'
import ChemicalsPage from '../component/Chemical/ChemicalsPage'
import { TankDetails } from '../component'
import Profile from '../component/Profile'
// import CompanyInvitation from '../component/Super/Invitations/CompanyInvitation'

const SuperUser = [
  // {
  //   path: ROUTES_PATH.INVITATION,
  //   component: CompanyInvitation,
  //   isProtected: true,
  // },
  {
    path: ROUTES_PATH.TENANTS,
    component: Tenants,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.SUPER_TENANT_DETAILS}/:tenant_id`,
    component: TenantDetails,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.CHEMICAL_LIST,
    component: ChemicalsPage,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.CHEMICAL_DETAILS}/:id`,
    component: ChemicalDetailsPage,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.CHEMICAL_ADD,
    component: ChemicalAdd,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.TANK_DETAILS}/:tank_registration_no`,
    component: TankDetails,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.EDIT_TENANT_SUPERUSER,
    component: EditTenantDetails,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.SUPER_USER_PROFILE,
    component: Profile,
    isProtected: true,
  },
  // {
  //   path: `${ROUTES_PATH.SUPER_INVITATION_DETAILS}/:invite_id`,
  //   component: InvitationDetails,
  //   isProtected: true,
  // },
]

export default SuperUser
