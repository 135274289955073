import React from 'react'
import styled from '@mui/material/styles/styled'
import Tooltip from '@mui/material/Tooltip'
import { tooltipClasses } from '@mui/material/Tooltip'

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, warning }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: warning ? '#D15D4A' : '#1A1A1A',
    color: 'white',
    fontSize: '14px',
    lineHeight: '15px',
    fontFamily: 'Lato',
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: warning ? '#D15D4A' : '#1A1A1A', // sets the color of the arrow
  },
}))
