import { Box, Button, Dialog, Typography } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import userAPI from '../../../api/user'
import { PaletteColors } from '../../../config/colors'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import LoadingIndicator from '../../Order/Tabs/Loading'
import routes from '../../../routes/route_contants'
import useAPILocal from '../../../hooks/useAPILocal'
import TextInput from '../../Reusable/Widgets/TextInput'
import useForm from '../../../hooks/useForm'
import { formatContactNo } from '../../../helpers/util'
import { toast } from 'react-toastify'

const fields = [
  {
    label: '',
    value: e => (
      <img
        src={`data:image/png;base64,${e?.profile_picture}`}
        alt={`${e?.first_name}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src =
            'https://www.pngkit.com/png/detail/281-2812821_user-account-management-logo-user-icon-png.png'
        }}
        style={{
          height: 38,
          width: 38,
          marginRight: 10,
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
    ),
  },
  {
    label: 'Name',
    value: e => `${e?.first_name} ${e?.last_name}`,
  },
  {
    label: 'Role',
    value: e =>
      e?.role?.role_name.charAt(0).toUpperCase() + e?.role?.role_name.slice(1),
  },
  { label: 'Email', value: e => e?.email },
  { label: 'Contact No.', value: e => formatContactNo(e?.phone_no) },
]

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function TenantPartnerInfo(props) {
  const userInfoApi = useAPILocal(userAPI.info)
  const [open, setOpen] = React.useState(false)
  const userUpdateStatusAPI = useAPILocal(userAPI.updateStatus)
  const [active, setActive] = React.useState()
  const [isUploading, setIsUploading] = React.useState(false)
  const [showCommentBox, setShowCommentBox] = React.useState(false)
  const path = useParams()
  const history = useHistory()

  const init = {
    comment: '',
  }

  const validationSchema = {
    comment: ['required'],
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit, setValues } =
    useForm(init, validationSchema, async result => {
      setIsUploading(true)

      userUpdateStatusAPI
        .request({
          comment: result.comment,
          email: userInfoApi?.data?.email,
          isActive: active,
        })
        .then(res => {
          toast.success('User status updated successfully')
          setIsUploading(false)
          setShowCommentBox(false)
          setOpen(false)
          window.location.reload()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Something went wrong')
        })
    })

  const handleClose = () => {
    setShowCommentBox(false)
    setOpen(false)
    setActive(prev => !prev)
  }
  const handleAgree = () => {
    setShowCommentBox(true)
  }
  const handleDisAgree = () => {
    setActive(prev => !prev)
    setShowCommentBox(false)
    setOpen(false)
  }

  useEffect(() => {
    setActive(userInfoApi?.data?.is_active)
  }, [userInfoApi?.data?.is_active])

  const handleChange = event => {
    setActive(prev => !prev)
    setOpen(true)
  }

  const getUserInfo = async () => {
    userInfoApi.request(path.user_id)
  }

  useEffect(() => {
    getUserInfo()
  }, [path.user_id])

  const handleEditClick = () => {
    history.push(`${routes.TEAM_MEMBERS_EDIT}/${path.user_id}`)
  }

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle
            backButton
            title={
              userInfoApi.isPending
                ? ''
                : `${`${userInfoApi?.data?.first_name} ${userInfoApi?.data?.last_name}`}`
            }
          />
          <Button variant="outlined" color="primary" onClick={handleEditClick}>
            Edit Details
          </Button>
        </PageHeading>
      </Fixed>
      <Scrollable p={3}>
        <Box
          className="shipments-list"
          style={{
            padding: 40,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto auto auto auto',
          }}
        >
          {userInfoApi.isPending ? (
            <LoadingIndicator m={4} />
          ) : (
            fields.map(field => (
              <Box key={field.label}>
                <Item
                  label={field.label}
                  value={field.value(userInfoApi.data)}
                />
              </Box>
            ))
          )}
          {userInfoApi.isPending ? (
            ''
          ) : (
            <div>
              {userInfoApi.data?.role?.role_name !== 'admin' && (
                <Box>
                  <Typography
                    style={{ fontSize: 14, color: PaletteColors.textMedium }}
                    variant="caption"
                  >
                    Status
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={active === true}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                    />
                  </FormGroup>
                </Box>
              )}
            </div>
          )}
        </Box>
        {open && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              Warning! Attempting to change user status
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {userInfoApi?.data?.is_active &&
                  `You are trying to change user status, from active to inactive, this will remove the user from the system and won't be accessible on any place`}
                {!userInfoApi?.data?.is_active &&
                  'You are trying to change user status, from inactive to active'}
              </DialogContentText>
              {showCommentBox && (
                <TextInput
                  placeholder={
                    userInfoApi?.data?.is_active
                      ? 'Comment For Inactivating user'
                      : 'Comment for activating user'
                  }
                  // label="Phone Number"
                  onChange={e => {
                    return handleOnChange({ name: 'comment', value: e })
                  }}
                  error={errors.comment}
                  value={values.comment}
                  disabled={false}
                  name="comment"
                  id="comment"
                  required
                />
              )}
            </DialogContent>
            {!showCommentBox && (
              <DialogActions>
                <Button onClick={handleDisAgree} color="primary">
                  Disagree
                </Button>
                <Button onClick={handleAgree} color="primary">
                  Agree
                </Button>
              </DialogActions>
            )}

            {showCommentBox && (
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  disabled={!values.comment}
                >
                  {isUploading ? 'Updating...' : 'Submit'}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}
      </Scrollable>
    </Container>
  )
}

export default TenantPartnerInfo
