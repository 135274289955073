import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import useForm from '../../../hooks/useForm'
import Modal from '../../Modal'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import TextInput from '../../Reusable/Widgets/TextInput'
import userAPI from '../../../api/user'
import useAPILocal from '../../../hooks/useAPILocal'
import Loading from '../../Loading'
import { toast } from 'react-toastify'

function TeamMembersInvitationModal({ isOpen, toggleModal, sendData }) {
  const inviteUser = useAPILocal(userAPI.inviteUser)

  const init = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    role: null,
  }

  const validationSchema = {
    name: ['required'],
    surname: ['required'],
    email: ['required', 'email'],
    phone: ['min:1', 'max:64', 'dashedNumber'],
    role: ['required'],
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit } = useForm(
    init,
    validationSchema,
    async result => {
      resetForm()
      inviteUser.reset()
      await inviteUser
        .request({
          name: result.name,
          surname: result.surname,
          phone: result.phone || null,
          email: result.email.toLowerCase(),
          role: Number(result.role),
        })
        .then(response => {
          sendData({
            sent: true,
            error: response.data.message || response.data.clientMessage,
          })
          toast.success('Team member invitation send successfully')
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Something went wrong')
        })
    }
  )

  useEffect(() => {
    setTimeout(() => {
      if (inviteUser.isResolved || inviteUser.isRejected) {
        toggleModal()
        inviteUser.reset()
      }
    }, 500)
  }, [inviteUser.isResolved, inviteUser.isRejected])

  return (
    <Modal visible={isOpen} onClose={toggleModal} title="Invite">
      {inviteUser.isPending && <Loading />}
      {!inviteUser.isRejected &&
        !inviteUser.isResolved &&
        !inviteUser.isPending && (
          <>
            <TextInput
              placeholder="Enter Name"
              label="Name"
              onChange={e => {
                return handleOnChange({ name: 'name', value: e })
              }}
              error={errors.name}
              value={values.name || ''}
              disabled={false}
              name="name"
              id="name"
            />
            <TextInput
              placeholder="Enter Surname"
              label="Surname"
              onChange={e => {
                return handleOnChange({ name: 'surname', value: e })
              }}
              error={errors.surname}
              value={values.surname || ''}
              disabled={false}
              name="surname"
              id="surname"
            />
            <TextInput
              placeholder="Enter Email Address"
              label="Email Address"
              onChange={e => {
                return handleOnChange({ name: 'email', value: e })
              }}
              error={errors.email}
              value={values.email || ''}
              disabled={false}
              name="email"
              id="email"
            />
            <TextInput
              placeholder="Enter Phone"
              label="Phone"
              onChange={e => {
                return handleOnChange({ name: 'phone', value: e })
              }}
              error={errors.phone}
              value={values.phone || ''}
              disabled={false}
              name="phone"
              id="phone"
            />
            <SelectInput
              emptyLabel="Select a User Role"
              label="Roles / Permission"
              name="role"
              options={[
                { value: '1', label: 'Admin' },
                { value: '9', label: 'Driver' },
              ]}
              onChange={e => {
                return handleOnChange({ name: 'role', value: e })
              }}
              error={errors.role}
              value={values.role || ''}
            >
              <option value="">Select a user role</option>
            </SelectInput>
            <Button
              style={{
                marginTop: '20px',
                marginRight: '15px',
              }}
              variant="contained"
              color="primary"
              disabled={inviteUser.isPending}
              onClick={handleSubmit}
            >
              Send Invitation
            </Button>
            <Button
              style={{
                marginTop: '20px',
                marginRight: '15px',
              }}
              variant="outlined"
              color="primary"
              onClick={toggleModal}
            >
              Cancel
            </Button>
          </>
        )}
    </Modal>
  )
}

export default TeamMembersInvitationModal
