import ROUTES_PATH from './route_contants'
import { TeamMemeber, ShipmentList, TankDetails } from '../component';
import CompanyDetails from '../component/MyAccount/CompanyDetails'
import LocationDetails from '../component/Location/LocationDetails'
import OrderList from '../component/Order/OrderList/index'
import CheckList from '../component/CheckList'
import TankList from '../component/Tank'
import PhotosAndCommentsView from '../component/Order/PhotosAndCommentsTab/PhotosAndCommentsView'
import ProductAdd from '../component/Tank/ProductAdd'
import Profile from '../component/Profile'
import ProfileEdit from '../component/Profile/ProfileEdit'
import ChemicalAdd from '../component/Chemical/ChemicalAdd';
import TankAdd from '../component/Tank/TankAdd';
import CreateChecklist from '../component/CheckList/CreateChecklist';
import ChecklistPage from '../component/CheckList/ChecklistPage';
import ProductDetailsPageGeneral from '../component/Tank/ProductDetailsPage';
import TenantDetailPage from '../component/Connections/Tabs/Explore/TenantDetailPage';
import ConnectionRequestDetailPage from '../component/Connections/Tabs/ConnectionRequest/ConnectionRequestDetailPage';
import PartnerPage from '../component/Connections/Tabs/Partners/PartnerPage';
import ProductDetailsPage from '../component/Connections/Tabs/MyCompany/ProductDetailPage';
import Connections from '../component/Connections';
import OrderDetails from '../component/Order/OrderDetails';
import MyCompany from '../component/MyAccount';
import EditTenantDetails from '../component/MyAccount/EditTenantDetails';
import TeamMembersInfo from '../component/MyAccount/TeamMembers/TeamMembersInfo';
import TeamMembersEdit from '../component/MyAccount/TeamMembers/TeamMembersEdit';
import TeamMembersInvite from '../component/MyAccount/TeamMembers/TeamMembersInvite';
import CreateContact from '../component/MyAccount/Contacts/CreateContact';

const Admin = [
  {
    path: ROUTES_PATH.TEAM_MEMBERS_INVITATION,
    component: TeamMembersInvite,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.MY_COMPANY,
    component: MyCompany,
    isProtected: true,
    queryParams: '/:entity?',
  },
  {
    path: ROUTES_PATH.EDITDETAILS_TENANT,
    component: EditTenantDetails,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.COMPANY_DETAILS,
    component: CompanyDetails,
    isProtected: true,
    queryParams: '/:tenant_id',
  },
  {
    path: ROUTES_PATH.COMPANY_PROFILE,
    component: Profile,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.USER_PROFILE,
    component: Profile,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.TEAM_MEMBERS_LIST,
    component: TeamMemeber,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.TEAM_MEMBERS_INFO,
    component: TeamMembersInfo,
    isProtected: true,
    queryParams: '/:user_id',
  },
  {
    path: ROUTES_PATH.TEAM_MEMBERS_EDIT,
    component: TeamMembersEdit,
    isProtected: true,
    queryParams: '/:user_id',
  },
  {
    path: ROUTES_PATH.CREATE_CONTACT,
    component: CreateContact,
    isProtected: true,
    queryParams: '',
  },
  {
    path: ROUTES_PATH.SHIPMENTS_LIST,
    component: ShipmentList,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.LOCATION_DETAILS,
    component: LocationDetails,
    isProtected: true,
    queryParams: '/:location_id',
  },
  {
    path: ROUTES_PATH.TANKS_LIST,
    component: TankList,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.TANK_DETAILS,
    component: TankDetails,
    isProtected: true,
    queryParams: '/:tank_registration_no',
  },
  {
    path: ROUTES_PATH.UPDATE_TANK,
    component: TankAdd,
    isProtected: true,
    queryParams: '/:tank_registration_no',
  },
  {
    path: ROUTES_PATH.ADD_TANK,
    component: TankAdd,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.ORDER_LIST,
    component: OrderList,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.ORDER_DETAILS,
    component: OrderDetails,
    isProtected: true,
    queryParams: '/:order_id',
  },
  {
    path: ROUTES_PATH.CHECK_LIST,
    component: CheckList,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.CHECKLIST_INFO,
    component: ChecklistPage,
    isProtected: true,
    queryParams: '/:checklist_id',
  },
  {
    path: ROUTES_PATH.CREATE_CHECKLIST,
    component: CreateChecklist,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.CHEMICAL_ADD,
    component: ChemicalAdd,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.PRODUCT_DETAILS,
    component: ProductDetailsPageGeneral,
    isProtected: true,
    queryParams: '/:id',
  },
  {
    path: ROUTES_PATH.PRODUCT_ADD,
    component: ProductAdd,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.PHOTOS_COMMENTS_VIEW,
    component: PhotosAndCommentsView,
    isProtected: true,
    queryParams: '/:comment_id',
  },
  {
    path: ROUTES_PATH.PROFILE_EDIT,
    component: ProfileEdit,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.CONNECTIONS,
    component: Connections,
    isProtected: true,
    // queryParams: '/:entity?',
  },
  {
    path: ROUTES_PATH.CONNECTION_TENANT_PAGE,
    component: TenantDetailPage,
    isProtected: true,
    queryParams: '/:tenant_id',
  },
  {
    path: ROUTES_PATH.CONNECTION_REQUEST_PAGE,
    component: ConnectionRequestDetailPage,
    isProtected: true,
    queryParams: '/:connection_id',
  },
  {
    path: ROUTES_PATH.CONNECTION_PARTNER_PAGE,
    component: PartnerPage,
    isProtected: true,
    queryParams: '/:connection_id',
  },
  {
    path: ROUTES_PATH.CONNECTION_PRODUCT_DETAILS,
    component: ProductDetailsPage,
    isProtected: true,
    queryParams: '/:id',
  },
]

export default Admin
