import React from 'react'
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui'
import { DropdownFilter } from './DropdownFilter'
import { UnitsFilterCell } from './UnitsFilterCell'

export const FilterCell = props => {
  const { column } = props
  if (
    column.name === 'estimatedShipment' ||
    column.name === 'estimatedArrival'
  ) {
    return <UnitsFilterCell {...props} />
  }
  if (column.name === 'tenant_role') {
    return <DropdownFilter columnName="tenant_role" {...props} />
  }
  if (column.name === 'status') {
    return <DropdownFilter columnName="status" {...props} />
  }
  return <TableFilterRow.Cell {...props} />
}
