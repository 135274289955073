import React, { useEffect, useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import PageTitle from '../../Reusable/PageTitle'
import Container from '../../Reusable/Container'
import Scrollable from '../../Reusable/Scrollable'
import TextInput from '../../Reusable/Widgets/TextInput'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import Loading from '../../Loading'
import useForm from '../../../hooks/useForm'
import useAPILocal from '../../../hooks/useAPILocal'
import userAPI from '../../../api/user'
import { toast } from 'react-toastify'

const TeamMembersEdit = () => {
  const history = useHistory()

  const userInfoApi = useAPILocal(userAPI.info)
  const updateUserDetails = useAPILocal(userAPI.updateUserDetails)
  const path = useParams()

  const init = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    roleId: null,
  }

  const validationSchema = {
    firstName: ['required'],
    lastName: ['required'],
    phoneNumber: ['required', 'min:1', 'max:64', 'dashedNumber'],
    roleId: ['required'],
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit, setValues } =
    useForm(init, validationSchema, async result => {
      // resetForm()
      result.roleId = parseInt(result.roleId)
      updateUserDetails
        .request(path.user_id, result)
        .then(res => {
          toast.success('User details updated successfully')
          history.goBack()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
    })

  useEffect(() => {
    userInfoApi.request(path.user_id).then(res =>
      setValues({
        firstName: res?.data?.first_name,
        lastName: res?.data?.last_name,
        phoneNumber: res?.data?.phone_no,
        roleId: res?.data?.role?.role_id,
      })
    )
  }, [])

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle backButton title="Edit User Details" />
        </PageHeading>
      </Fixed>
      {userInfoApi?.status === 'pending' ? (
        <Loading />
      ) : (
        <Scrollable mt={10} style={{ height: '100%' }}>
          <Box display="flex">
            <Box flex="10%"></Box>
            <Box flex="80%" height="100%">
              <Box overflow="auto" height="100%"></Box>
              <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
                <Box display="flex" className="form-row">
                  <TextInput
                    placeholder="Enter First Name"
                    label="First Name"
                    onChange={e => {
                      return handleOnChange({ name: 'firstName', value: e })
                    }}
                    error={errors.firstName}
                    value={values.firstName}
                    disabled={false}
                    name="firstName"
                    id="firstName"
                    flex={0.4}
                  />
                  <TextInput
                    placeholder="Enter First Name"
                    label="Last Name"
                    onChange={e => {
                      return handleOnChange({ name: 'lastName', value: e })
                    }}
                    error={errors.lastName}
                    value={values.lastName}
                    disabled={false}
                    name="lastName"
                    id="lastName"
                    flex={0.4}
                  />
                </Box>
                <Box display="flex" className="form-row">
                  <TextInput
                    placeholder="Enter Phone Number"
                    label="Phone Number"
                    onChange={e => {
                      return handleOnChange({ name: 'phoneNumber', value: e })
                    }}
                    error={errors.phoneNumber}
                    value={values.phoneNumber}
                    disabled={false}
                    name="phoneNumber"
                    id="phoneNumber"
                    flex={0.4}
                  />
                  <SelectInput
                    emptyLabel="Select a User Role"
                    label="Roles / Permission"
                    name="roleId"
                    options={[
                      { value: '1', label: 'Admin' },
                      { value: '9', label: 'Driver' },
                    ]}
                    onChange={e => {
                      return handleOnChange({ name: 'roleId', value: e })
                    }}
                    value={values.roleId || ''}
                    flex={0.4}
                  >
                    <option value="">Select a user role</option>
                  </SelectInput>
                </Box>
              </Box>
            </Box>
          </Box>
        </Scrollable>
      )}
      <Fixed>
        <Box py={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={updateUserDetails.status === 'pending'}
          >
            {updateUserDetails.status === 'pending' ? (
              <Loading />
            ) : (
              'Save Changes'
            )}
          </Button>
        </Box>
      </Fixed>
    </Container>
  )
}

export default TeamMembersEdit
