import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import moment from 'moment'
import BlockIcon from '@material-ui/icons/Block'
import TableHead from '../../Reusable/TableHead'
import Scrollable from '../../Reusable/Scrollable'
import TableBody from '../../Reusable/TableBody'
import Container from '../../Reusable/Container'
import Loading from '../../Loading'
import userAPI from '../../../api/user'
import Modal from '../../Modal'
import useStateValue from '../../../hooks/useStateValue'
import useAPILocal from '../../../hooks/useAPILocal'
import { toast } from 'react-toastify'

function TeamMembersInviteList({ type, apiResult, currentPage }) {
  const updateInvite = useAPILocal(userAPI.updateInvite)
  const [isOpen, setIsOpen] = useState(false)
  const [currentData, setCurrentData] = useState()
  const [state] = useStateValue()
  const toggleModal = e => {
    setIsOpen(!isOpen)
    setCurrentData(e)
  }

  const cancelInvite = () => {
    const cancelObj = {
      name: currentData.name,
      surname: currentData.surname,
      email: currentData.email,
      role: currentData.role.role_id,
      status: 'cancelled',
    }
    updateInvite.request(currentData.id, cancelObj)
      .then(toggleModal).then(
        res => {
          toast.success('Invitation canceled')
          if (state) apiResult.request({
            page: currentPage,
            limit: 12,
            status: 'invited',
            tenantId: state?.user?.user_info?.tenant?.tenant_id,
          })
        })
      .catch(error => {
        toast.error(error?.clientMessage || error?.message || 'Error')
      })
  }

  const CancelInviteModal = (
    <Modal visible={isOpen} onClose={() => setIsOpen(!isOpen)} cancel>
      {updateInvite.isPending && <Loading />}
      {!updateInvite.isRejected &&
        !updateInvite.isResolved &&
        !updateInvite.isPending && (
          <>
            <Typography>Do you want to cancel the invitation?</Typography>
            <Button
              style={{
                marginTop: '20px',
                marginRight: '15px',
              }}
              variant="contained"
              color="primary"
              // disabled={inviteUser.isPending}
              onClick={() => cancelInvite()}
            >
              Cancel Invitation
            </Button>
            <Button
              style={{
                marginTop: '20px',
                marginRight: '15px',
              }}
              variant="outlined"
              color="primary"
              onClick={toggleModal}
            >
              Cancel
            </Button>
          </>
        )}
    </Modal>
  )

  useEffect(() => {
    setTimeout(() => {
      if (updateInvite.isResolved) {
        setIsOpen(false)
      }
      updateInvite.reset()
    }, 2000)
  }, [updateInvite.isResolved])

  const columns = [
    {
      label: 'User Email',
      value: e => e?.email,
      maxWidth: 250,
      flex: 3,
    },
    {
      label: 'Invited By',
      value: e => e?.invitedBy,
      flex: 2,
    },
    {
      label: 'Invited On',
      value: e =>
        moment(e?.invitedOn)
          .format('llll')
          .slice(0, e?.invitedOn.length - 9),
      flex: 2,
    },
    {
      label: 'Role',
      value: e => (e?.role?.role_id === 1 ? 'Admin' : 'Driver'),
    },
    {
      label: 'Status',
      value: e => e?.status,
    },
    {
      label: null,
      value: e =>
        e.status === 'invited' ? (
          <>
            <Button
              variant="primary"
              disableRipple
              style={{ color: '#E15342' }}
              // onClick={() => cancelInvite(e)}
              onClick={() => toggleModal(e)}
            >
              <BlockIcon fontSize="small" style={{ marginRight: '5px' }} />
              Cancel Invite
            </Button>
            {CancelInviteModal}
          </>
        ) : null,
      flex: 2,
    },
  ]

  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody columns={columns} data={apiResult.data?.results} />
        )}
      </Scrollable>
    </Container>
  )
}

TeamMembersInviteList.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

TeamMembersInviteList.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default TeamMembersInviteList
