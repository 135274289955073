import React from 'react'
import { StyledButton } from './StyledButton'
import { classes } from './utils'
export const ResetWidthButton = props => {
  const { resetWidths } = props
  return (
    <StyledButton
      onClick={resetWidths}
      variant="outlined"
      size="medium"
      className={classes.button}
    >
      Reset widths to default
    </StyledButton>
  )
}
