import { Box, Button, Tab, Tabs } from '@material-ui/core'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  PagingPanel,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import {
  CustomPaging,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid'
import Paper from '@mui/material/Paper'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { useHistory } from 'react-router-dom'
import saveAs from 'file-saver'
import useStateValue from '../../../hooks/useStateValue'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import TabPanel from '../../Reusable/TabPanel'
import useAPILocal from '../../../hooks/useAPILocal'
import bolAPI from '../../../api/bol'
import routes from '../../../routes/route_contants'

import LoadingIndicator from '../Tabs/Loading'
import { ResizingPanel } from './ResizingPanel'
import { CellComponent } from './CellComponent'
import { RowComponent } from './RowComponent'
import { TableComponent } from './styledComponents/TableComponent'
import { columns } from './columnDefs'
import { FilterCell } from './FilterCell'

const onSave = workbook => {
  workbook.xlsx.writeBuffer().then(buffer => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'DataGrid.xlsx'
    )
  })
}

const TABS = [
  { label: 'Summary' },
  { label: 'Upcoming' },
  { label: 'Previous' },
]

const defaultColumnWidth = [
  {
    columnName: 'estimatedShipment',
    width: 180,
  },
  {
    columnName: 'customerPoFilter',
    width: 180,
  },
  {
    columnName: 'bolNoFilter',
    width: 180,
  },
  {
    columnName: 'shipperNameFilter',
    width: 180,
  },
  {
    columnName: 'carrierNameFilter',
    width: 180,
  },
  {
    columnName: 'receiverNameFilter',
    width: 180,
  },
  {
    columnName: 'productNameFilter',
    width: 180,
  },
  {
    columnName: 'productCodeFilter',
    width: 180,
  },
  {
    columnName: 'status',
    width: 250,
  },
  {
    columnName: 'estimatedArrival',
    width: 180,
  },
  {
    columnName: 'tenant_role',
    width: 180,
  },
]

function OrderList() {
  const [showFilters, setShowFilters] = useState(false)
  const [{ table_filters }, dispatch] = useStateValue()
  const history = useHistory()
  const initialFilters = {
    limit: 50,
    sorting: [],
    filters: [],
    columnOrder: [
      'estimatedShipment',
      'shipperNameFilter',
      'carrierNameFilter',
      'receiverNameFilter',
      'productNameFilter',
      'productCodeFilter',
      'customerPoFilter',
      'bolNoFilter',
      'status',
      'estimatedArrival',
      'tenant_role',
    ],

    columnWidths: defaultColumnWidth,
    resizingMode: 'widget',
    hiddenColumnNames: [],
  }
  const state =
    table_filters?.columnOrder.includes('customerPo') ||
    table_filters?.columnOrder.includes('createdOn') ||
    !table_filters
      ? initialFilters
      : table_filters
  const setState = newState =>
    dispatch({
      type: `SET_TABLE_FILTERS`,
      table_filters: newState,
    })
  const exporterRef = useRef(null)
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])
  const [currentTab, setCurrentTab] = useState(0)
  const [searchStr, setSearchStr] = useState(undefined)
  const [page, setPage] = useState(0)
  const today = new Date()
  const tomorrow = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  tomorrow.setDate(today.getDate() + 1)

  const API = useAPILocal(bolAPI.list)
  // const handleSearch = val => {
  //   if (val?.length >= 3) setSearchStr(val)
  //   else setSearchStr(undefined)
  // }

  function mapDateSelectionFilters() {
    return state.filters.reduce((prev, curr) => {
      prev = { ...prev }
      if (
        ['estimatedShipment'].includes(curr.columnName) &&
        curr?.selection?.startDate &&
        curr?.selection?.endDate
      ) {
        prev.estimatedShipmentFilterStart = curr?.selection?.startDate
        prev.estimatedShipmentFilterEnd = curr?.selection?.endDate
      } else if (
        ['estimatedArrival'].includes(curr.columnName) &&
        curr?.selection?.startDate &&
        curr?.selection?.endDate
      ) {
        prev.estimatedArrivalFilterStart = curr?.selection?.startDate
        prev.estimatedArrivalFilterEnd = curr?.selection?.endDate
      } else {
        prev[curr.columnName] = curr.value
      }
      return prev
    }, {})
  }

  const handleFilter = () => {
    const filters = mapDateSelectionFilters()
    const ordering = mapOrdering(state.sorting?.[0]?.columnName)
    const sorting = {
      ordering: ordering,
      orderDirection: state.sorting?.[0]?.direction?.toUpperCase(),
    }
    let apiRequest = {
      start_date: currentTab === 1 ? tomorrow : undefined,
      end_date: currentTab === 2 ? yesterday : undefined,
      ...filters,
      search: searchStr,
      limit: state.limit,
      page: page + 1,
    }
    if (sorting.ordering) apiRequest = { ...apiRequest, ...sorting }
    API.request(apiRequest)
  }

  function mapOrdering(columnName) {
    switch (columnName) {
      case 'estimatedShipment':
        return 'estimatedShipment'
      case 'shipperNameFilter':
        return 'shipper.tenantName'
      case 'carrierNameFilter':
        return 'route.carrier.tenantName'
      case 'receiverNameFilter':
        return 'receiver.tenantName'
      case 'productNameFilter':
        return 'bolItems.sellerProduct.name'
      case 'productCodeFilter':
        return 'bolItems.sellerProduct.code'
      case 'customerPoFilter':
        return 'customerPo'
      case 'bolNoFilter':
        return 'bolNo'
      case 'status':
        return 'status'
      case 'estimatedArrival':
        return 'estimatedArrival'
      case 'tenant_role':
        return ''
      default:
        return ''
    }
  }

  useEffect(() => {
    const filters = mapDateSelectionFilters()
    const ordering = mapOrdering(state.sorting?.[0]?.columnName)
    const sorting = {
      ordering: ordering,
      orderDirection: state.sorting?.[0]?.direction?.toUpperCase(),
    }
    let temp = {
      start_date: currentTab === 1 ? tomorrow : undefined,
      end_date: currentTab === 2 ? yesterday : undefined,
      ...filters,
      search: searchStr,
      limit: state.limit,
      page: page + 1,
    }
    if (sorting.ordering) temp = { ...temp, ...sorting }
    API.request(temp)
  }, [JSON.stringify(state.sorting), page, state.limit, searchStr, currentTab])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleFilter()
    }, 700)
    return () => clearTimeout(debounceTimer)
  }, [JSON.stringify(state.filters)])

  useEffect(() => {
    setState({ ...state, filters: [] })
  }, [])

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Orders" />

          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.ADD_SHIPMENT)}
          >
            Add Order
          </Button>
        </PageHeading>

        <Box mt={2}>
          <PageHeading alignItems="center">
            <Tabs
              value={currentTab}
              onChange={(e, v) => setCurrentTab(v)}
              indicatorColor="white"
            >
              {TABS.map((tab, index) => (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  style={
                    index === currentTab
                      ? {
                          color: 'white',
                          backgroundColor: '#3298FF',
                          height: '100%',
                          borderRadius: '30px',
                        }
                      : {}
                  }
                />
              ))}
            </Tabs>
            {/* <SearchBar onChange={handleSearch} /> */}
          </PageHeading>
        </Box>
      </Fixed>

      {API.isPending ? (
        <LoadingIndicator />
      ) : (
        TABS.map((tab, index) => (
          <TabPanel key={`PANEL-${tab.label}`} value={currentTab} index={index}>
            <Paper style={{ position: 'relative', pointer: 'cursor' }}>
              <Grid
                rows={API?.data?.results || []}
                columns={columns}
                style={{ height: '30px' }}
              >
                <DragDropProvider />
                <SearchState onValueChange={setSearchStr} />

                <VirtualTable
                  cellComponent={props => (
                    <CellComponent state={state} {...props} />
                  )}
                />
                <FilteringState
                  filters={state.filters}
                  onFiltersChange={e => setState({ ...state, filters: e })}
                />
                <PagingState
                  currentPage={page}
                  onCurrentPageChange={e => setPage(e)}
                  pageSize={state.limit}
                  onPageSizeChange={e => setState({ ...state, limit: e })}
                />
                <CustomPaging totalCount={API?.data?.count || 0} />
                <SortingState
                  sorting={state.sorting}
                  onSortingChange={e => setState({ ...state, sorting: [e[0]] })}
                />
                <IntegratedSorting />
                <IntegratedFiltering />
                {/* <Table /> */}
                <SelectionState
                  selection={[]}
                  onSelectionChange={data => {
                    const index = data?.[0]
                    if (!_.isNumber(index)) return
                    history.push(
                      `${routes.ORDER_DETAILS}/${API?.data?.results?.[index]?.id}`
                    )
                  }}
                />
                <TableSelection
                  rowComponent={RowComponent}
                  selectByRowClick
                  highlightRow
                  showSelectionColumn={false}
                />
                <TableColumnResizing
                  columnWidths={state.columnWidths}
                  onColumnWidthsChange={e => {
                    setState({ ...state, columnWidths: e })
                  }}
                />
                <TableColumnReordering
                  order={state.columnOrder}
                  onOrderChange={e => setState({ ...state, columnOrder: e })}
                />
                <TableHeaderRow
                  tableComponent={TableComponent}
                  showSortingControls
                />
                {showFilters && <TableFilterRow cellComponent={FilterCell} />}
                <TableFixedColumns leftColumns={[]} rightColumns={[]} />
                <TableColumnVisibility
                  hiddenColumnNames={state.hiddenColumnNames}
                  onHiddenColumnNamesChange={e => {
                    setState({
                      ...state,
                      hiddenColumnNames: e,
                    })
                  }}
                />

                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                {/* <SearchPanel /> */}
                <PagingPanel pageSizes={[20, 50, 100, 150]} />
                <ResizingPanel
                  defaultValue={state.resizingMode}
                  changeMode={e => setState({ ...state, resizingMode: e })}
                  resetWidths={() => {
                    setState({ ...state, columnWidths: defaultColumnWidth })
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                  setDefaultFilters={() => setState({ ...state, filters: [] })}
                />
              </Grid>
              <GridExporter
                ref={exporterRef}
                rows={API?.data?.results || []}
                columns={columns}
                onSave={onSave}
              />
            </Paper>
          </TabPanel>
        ))
      )}
    </Container>
  )
}

export default OrderList
