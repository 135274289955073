import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core'
import { toast } from 'react-toastify';
import Geocode from 'react-geocode'
import Loading from '../Loading'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageTitle from '../Reusable/PageTitle'
import PageHeading from '../Reusable/PageHeading'
import Scrollable from '../Reusable/Scrollable'
import { PaletteColors } from '../../config/colors'
import { formatLocation } from '../../helpers/util'
import AddLocationModal from './AddLocationModal'
import useAPILocal from '../../hooks/useAPILocal'
import matchersAPI from '../../api/matchers'
import useForm from '../../hooks/useForm'
import Modal from '../Modal'
import TextInput from '../Reusable/Widgets/TextInput'
import LoadingIndicator from '../Order/Tabs/Loading'
import locationAPI from '../../api/location'

const LoadingContainer = () => {
  return <Loading />
}

function LocationDetails(props) {
  const param = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [displayAdd, setDisplayAdd] = useState(true)
  const [matchers, setMatchers] = useState()
  const [companyLocation, setCompanyLocation] = useState({})
  const history = useHistory()
  const [detailsVisible, setDetailsVisible] = React.useState(false)
  const toggleDetailsVisible = () => setDetailsVisible(prev => !prev)
  const partnerId = history?.location?.state?.partnerId
  const [open, setOpen] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)

  const getLocation = useAPILocal(locationAPI.getLocation)
  const deleteLocation = useAPILocal(locationAPI.deleteLocation)

  const getLocationMatchingStrings = useAPILocal(
    matchersAPI.getLocationMatchingStrings
  )

  useEffect(() => {
    if (param.location_id)
      getLocation
        .request(param.location_id)
        .then(res => setCompanyLocation(res.data))
  }, [openUpdate])

  useEffect(() => {
    if (partnerId)
      getLocationMatchingStrings
        .request(partnerId, param.location_id)
        .then(res => setMatchers(res.data))
  }, [])

  const setAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results[0].formatted_address
        setDisplayAdd(address)
      },
      error => {
        console.error(error)
      }
    )
  }

  useEffect(() => {
    const { latitude, longitude } = companyLocation
    setAddress(latitude, longitude)
  }, [companyLocation])

  const onLocationAdded = (res, type) => {
    if (type === 'update') {
      if (param.location_id)
        getLocation
          .request(param.location_id)
          .then(result => setCompanyLocation(result.data))
    }
  }

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)

  const updateLocationMatchingStrings = useAPILocal(
    matchersAPI.updateLocationMatchingStrings
  )

  const [isOpenMatcher, setIsOpenMatcher] = useState(false)

  const toggleModalMatcher = () => {
    setIsOpenMatcher(!isOpenMatcher)
  }
  const onCloseMatcher = () => {
    setIsOpenMatcher(false)
  }

  const init = {
    code: '',
  }

  const validationSchema = {
    code: ['required'],
  }

  const { errors, handleOnChange, values, resetForm, handleSubmit, setValues } =
    useForm(init, validationSchema, async result => {
      const strings = result.code.split('\n').filter(item => item.length !== 0)
      updateLocationMatchingStrings.request(
        partnerId,
        param.location_id,
        strings
      )
        .then(res => {
          toast.success('ERP/TMS numbers edited successfully')
          resetForm()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
      setTimeout(() => {
        if (
          updateLocationMatchingStrings.isRejected ||
          updateLocationMatchingStrings.isResolved
        )
          toggleModalMatcher()
        window.location.reload()
      }, 2000)
    })

  useEffect(() => {
    setValues({ code: matchers?.map(item => item.matcher).join('\n') })
  }, [matchers])

  const Item = () => {
    return (
      <Box
        style={{ display: 'flex', justifyContent: 'flex-start', gap: '80px' }}
      >
        {/*<Box>*/}
        {/*  <Typography*/}
        {/*    style={{ fontSize: 14, color: PaletteColors.textMedium }}*/}
        {/*    variant="caption"*/}
        {/*  >*/}
        {/*    Location Name*/}
        {/*  </Typography>*/}
        {/*  <Typography*/}
        {/*    style={{ fontSize: 16, color: PaletteColors.textDark }}*/}
        {/*    variant="body1"*/}
        {/*  >*/}
        {/*    <p>{`${companyLocation?.location_name}`}</p>*/}
        {/*  </Typography>*/}
        {/*</Box>*/}

        <Box>
          <Typography
            style={{ fontSize: 14, color: PaletteColors.textMedium }}
            variant="caption"
          >
            Address
          </Typography>
          <Typography
            style={{ fontSize: 16, color: PaletteColors.textDark }}
            variant="body1"
          >
            <p>{`${formatLocation(companyLocation)}`}</p>
          </Typography>
        </Box>

        <Box>
          <Typography
            style={{ fontSize: 14, color: PaletteColors.textMedium }}
            variant="caption"
          >
            ERP/TMS Number(s)
          </Typography>

          <Typography
            style={{ fontSize: 16, color: PaletteColors.textDark }}
            variant="body1"
          >
            {partnerId
              ? matchers?.length !== 0
                ? matchers?.map(item => item?.matcher).join(', ')
                : '-'
              : companyLocation?.facility_number || '-'}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Container pt={3} px={3}>
        <Fixed>
          <PageHeading>
            <PageTitle
              title="Location Details"
              backButton={() => history.goBack('')}
            />
            <Box>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  color: 'red',
                  borderColor: 'red',
                  marginRight: '10px',
                }}
                onClick={() => setOpen(true)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => toggleModal()}
                style={{ marginRight: '10px' }}
              >
                Edit Location
              </Button>
              {partnerId && (
                <Button
                  onClick={toggleModalMatcher}
                  variant="contained"
                  color="primary"
                >
                  Edit ERP/TMS
                </Button>
              )}
              {open && (
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Are you sure you want to delete this location ?
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                      No
                    </Button>
                    <Button
                      onClick={() => {
                        deleteLocation.request(param.location_id)
                          .then(() => {
                            setOpen(false)
                            toast.success('Location deleted successfully')
                            history.push('/my-account', { action: 'delete' })
                          })
                          .catch(error => {
                            setOpen(false)
                            toast.error(error?.clientMessage || error?.message || 'Error')
                          })
                      }}
                      color="primary"
                    >
                      {deleteLocation.isPending ? <LoadingIndicator /> : 'Yes'}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
          </PageHeading>
        </Fixed>
        <Scrollable p={0}>
          <Box
            style={{
              padding: 20,
              backgroundColor: PaletteColors.backgroundPrimary,
              borderRadius: 9,
              marginTop: 30,
            }}
          >
            <div style={{ padding: '20px' }}>
              {getLocation.isPending ? (
                <Loading />
              ) : (
                <Box>
                  <Item />
                </Box>
              )}
            </div>
          </Box>
        </Scrollable>
      </Container>
      <Map
        containerStyle={{
          height: '60vh',
          position: 'relative',
          margin: '24px',
          width: '96%',
        }}
        zoom={15}
        initialCenter={{
          lat: companyLocation.latitude,
          lng: companyLocation.longitude,
        }}
        center={{
          lat: companyLocation.latitude,
          lng: companyLocation.longitude,
        }}
        google={window.google}
        streetView
      >
        <Marker
          onClick={toggleDetailsVisible}
          position={{
            lat: companyLocation.latitude,
            lng: companyLocation.longitude,
          }}
          icon={{
            url: 'https://i.pinimg.com/originals/25/62/aa/2562aacd1a4c2af60cce9629b1e05cf2.png',
            scaledSize: new window.google.maps.Size(40, 40),
          }}
        />

        <InfoWindow
          visible={detailsVisible}
          position={{
            lat: companyLocation.latitude,
            lng: companyLocation.longitude,
          }}
          onClose={toggleDetailsVisible}
        >
          <div style={{ height: '30px' }}>
            <p>{displayAdd}</p>
          </div>
        </InfoWindow>
      </Map>

      {companyLocation && (
        <AddLocationModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          onDone={onLocationAdded}
          location={companyLocation}
        />
      )}
      <Modal visible={isOpenMatcher} onClose={onCloseMatcher}>
        <Typography color="primary" style={{ fontWeight: '700' }}>
          TMS Number
        </Typography>
        <TextInput
          placeholder="Enter one code per line"
          label="Enter matcher codes (one code per line)"
          onChange={e => {
            return handleOnChange({ name: 'code', value: e })
          }}
          error={errors.code}
          value={values.code}
          disabled={false}
          name="code"
          id="code"
          multiline
          multilineHeight="130px"
        />
        <Button
          style={{
            marginTop: '20px',
            marginRight: '15px',
            width: '100%',
          }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={updateLocationMatchingStrings.isPending}
        >
          {updateLocationMatchingStrings.isPending ? (
            <Loading color="#FFF" />
          ) : (
            'Save'
          )}
        </Button>
      </Modal>
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  LoadingContainer,
})(LocationDetails)
