import { styled } from '@material-ui/core'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { classes } from './utils'
export const StyledTable = styled(Table.Table)(({ theme }) => ({
  [`&.${classes.tableStriped}`]: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  },
}))
