import client from '../../hooks/useClient'

const allowedStatuses = [
  'PRE_SHIPMENT',
  'ARRIVED_TO_LOAD',
  'IN_TRANSIT',
  'ARRIVED_TO_UNLOAD',
  'COMPLETED',
]
const bolAPI = {}

bolAPI.getDocuments = (bolId, params) => {
  return client.get(`/bol/${bolId}/documents`, params)
}

bolAPI.listBolItems = bolId => client.get(`/bol/item/list/${bolId}`)

bolAPI.list = params => {
  if (params && params.status) {
    if (allowedStatuses.includes(params.status)) {
      params.statusFilter = params.status
      delete params.status
    } else {
      throw new Error(`Invalid status: ${params.status}`)
    }
  }
  return client.get(`/bol/list`, params)
}

bolAPI.bolDetails = (bolId, params) => {
  return client.get(`/bol/${bolId}`, params)
}

bolAPI.bolChecklist = (bolId, params) => {
  return client.get(`/bol/${bolId}/checklists`, params)
}

bolAPI.bolComments = (bolId, data) => {
  const formData = new FormData()
  data.images.forEach(image => {
    formData.append('images', image)
  })
  if (data.comment) formData.append('comment', data.comment)
  return client.post(`/bol/${bolId}/comments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

bolAPI.getComments = (bolId, params) => {
  return client.get(`/bol/${bolId}/comments`, params)
}

bolAPI.getSingleComment = commentId => {
  return client.get(`/bol/comments/${commentId}`)
}

bolAPI.updateProduct = (bolItemId, data) => {
  return client.patch(`/bol/item/info/${bolItemId}`, data)
}

bolAPI.updateEstimatedArrival = data => {
  return client.patch(
    `/bol/${data?.bolId}/estimated-arrival?timestamp=${data?.timestamp}`
  )
}

export default bolAPI
