import { Box, Button } from '@material-ui/core'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'

import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import TextInput from '../../Reusable/Widgets/TextInput'

import useForm from '../../../hooks/useForm'

import Loading from '../../Loading'

import useAPILocal from '../../../hooks/useAPILocal'
import contactsAPI from '../../../api/contacts'

function CreateContact(props) {
  const mode = props.location?.state?.mode || 'create'
  const history = useHistory()

  const createContact = useAPILocal(contactsAPI.createContact)
  const editContact = useAPILocal(contactsAPI.editContact)
  const getSingleContact = useAPILocal(contactsAPI.getSingleContact)
  const { id } = history.location.state
  const init = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    description: '',
    jobTitle: '',
  }

  const validationSchema = {
    name: ['required'],
    surname: ['required'],
    email: ['required', 'email'],
    phone: ['required', 'min:1', 'max:12', 'dashedNumber'],
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit, setValues } =
    useForm(init, validationSchema, async result => {
      if (mode === 'create') {
        resetForm()
        createContact.reset()
        await createContact.request({
          email: result.email.toLowerCase(),
          ...result,
        })
          .then(res => {
            toast.success('Contact created successfully')
            history.goBack()
          })
          .catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
          })
      } else {
        await editContact.request(id, {
          email: result.email.toLowerCase(),
          ...result,
        })
          .then(res => {
            toast.success('Contact updated successfully')
            history.goBack()
          })
          .catch(error => {
            toast.error(error?.clientMessage || error?.message || 'Error')
          })
      }
    })

  useEffect(() => {
    setTimeout(() => {
      if (createContact.isResolved) {
        // toggleModal()
      }
      createContact.reset()
    }, 2000)
  }, [createContact.isResolved])

  useEffect(() => {
    if (id)
      getSingleContact.request(id).then(res => {
        setValues({
          name: res.data.name,
          surname: res.data.surname,
          email: res.data.email,
          phone: res.data.phone,
          description: res.data.description,
          jobTitle: res.data.jobTitle,
        })
      })
  }, [])

  return (
    <>
      {createContact.isPending && getSingleContact.isPending && <Loading />}
      {!createContact.isRejected &&
        !createContact.isResolved &&
        !createContact.isPending && (
          <Container pt={3} px={3}>
            <Fixed>
              <PageHeading>
                <PageTitle
                  title={mode === 'create' ? 'Add Contact' : 'Edit Contact'}
                  backButton
                />
              </PageHeading>
            </Fixed>

            <Scrollable mt={4} style={{ height: '100%' }}>
              <>
                <Box
                  display="flex"
                  className="form-row"
                  style={{ justifyContent: 'center' }}
                >
                  <TextInput
                    placeholder="Enter name"
                    label="Name"
                    flex="0.4"
                    onChange={e => {
                      return handleOnChange({ name: 'name', value: e })
                    }}
                    error={errors.name}
                    value={values.name}
                    disabled={false}
                    name="name"
                    id="name"
                  />
                  <TextInput
                    placeholder="Enter surname"
                    label="Surname"
                    flex="0.4"
                    onChange={e => {
                      return handleOnChange({ name: 'surname', value: e })
                    }}
                    error={errors.surname}
                    value={values.surname}
                    disabled={false}
                    name="surname"
                    id="surname"
                  />
                </Box>

                <Box
                  display="flex"
                  className="form-row"
                  style={{ justifyContent: 'center' }}
                >
                  <TextInput
                    flex="0.4"
                    placeholder="Enter Email Address"
                    label="Email Address"
                    onChange={e => {
                      return handleOnChange({ name: 'email', value: e })
                    }}
                    error={errors.email}
                    value={values.email}
                    disabled={false}
                    name="email"
                    id="email"
                  />
                  <TextInput
                    flex="0.4"
                    placeholder="Enter phone number"
                    label="Phone Number"
                    onChange={e => {
                      return handleOnChange({ name: 'phone', value: e })
                    }}
                    error={errors.phone}
                    value={values.phone}
                    disabled={false}
                    name="phone"
                    id="phone"
                  />
                </Box>
                <Box
                  display="flex"
                  className="form-row"
                  style={{ justifyContent: 'center' }}
                >
                  <TextInput
                    flex="0.4"
                    placeholder="Enter description"
                    label="Description"
                    onChange={e => {
                      return handleOnChange({ name: 'description', value: e })
                    }}
                    error={errors.description}
                    value={values.description}
                    disabled={false}
                    name="description"
                    id="description"
                  />

                  <TextInput
                    flex="0.4"
                    placeholder="Enter job title"
                    label="Job Title"
                    onChange={e => {
                      return handleOnChange({ name: 'jobTitle', value: e })
                    }}
                    error={errors.jobTitle}
                    value={values.jobTitle}
                    disabled={false}
                    name="jobTitle"
                    id="jobTitle"
                  />
                </Box>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '50px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    // disabled={value === 'SELECTED_PARTNERS'}
                    onClick={handleSubmit}
                  >
                    {mode === 'create' ? 'Add Contact' : 'Save'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.goBack()}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            </Scrollable>
          </Container>
        )}
    </>
  )
}

export default CreateContact
