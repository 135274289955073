import React from 'react'
import { TableSelection } from '@devexpress/dx-react-grid-material-ui'

export const RowComponent = ({ tableRow, ...restProps }) => {
  const hasWarningIssues =
    tableRow.row.issues.filter(i => i.type === 'warning').length > 0
  return (
    <TableSelection.Row
      {...restProps}
      style={hasWarningIssues ? { backgroundColor: '#FBE6E5' } : {}}
    />
  )
}
