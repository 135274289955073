import React from 'react'
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { FormControlLabel, Switch } from '@mui/material'
import { ResetWidthButton } from './styledComponents/ResetWidthButton'

export const ResizingPanel = props => (
  <Plugin name="ResizingPanel">
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <ResetWidthButton {...props} />
      <FormControlLabel
        labelPlacement="start"
        onChange={e => {
          props.setShowFilters(e.target.checked)
          props.setDefaultFilters()
        }}
        checked={props.showFilters}
        control={<Switch defaultChecked />}
        label="Filters"
      />
      {/* <ModeSelector {...props} /> */}
    </Template>
  </Plugin>
)
