import React from 'react'
import moment from 'moment'
import { dateFormatPattern } from '../../../helpers/util'
import Carrier from '../../../assets/carrier.svg'
import Receiver from '../../../assets/receiver.svg'
import Shipper from '../../../assets/shipper.svg'

const getType = item => {
  switch (item) {
    case 'receiver':
      return Receiver
    case 'carrier':
      return Carrier
    case 'shipper':
      return Shipper
    default:
      break
  }
}
export const columns = [
  {
    name: 'estimatedShipment',
    title: 'Ship Date',
    getCellValue: row =>
      row?.estimatedShipment
        ? moment(row?.estimatedShipment).format(dateFormatPattern())
        : 'Not set',
  },
  {
    name: 'customerPoFilter',
    title: 'Customer PO',
    getCellValue: row => row?.customerPo,
  },
  {
    name: 'bolNoFilter',
    title: 'BOL No.',
    getCellValue: row => row?.bolNo,
  },
  {
    name: 'shipperNameFilter',
    title: 'Shipper',
    getCellValue: row => row?.shipper?.tenant_name,
  },
  {
    name: 'carrierNameFilter',
    title: 'Carrier',
    getCellValue: row => row?.route?.carrier?.tenant_name,
  },
  {
    name: 'receiverNameFilter',
    title: 'Receiver',
    getCellValue: row => row?.receiver?.tenant_name,
  },
  {
    name: 'productNameFilter',
    title: 'Product Name',
    getCellValue: row => row?.bolItems?.[0]?.product?.name,
  },
  {
    name: 'productCodeFilter',
    title: 'Product Number',
    getCellValue: row => row?.bolItems?.[0]?.product?.code,
  },
  {
    name: 'status',
    title: 'Status',
    getCellValue: row => row?.status.replaceAll('_', ' '),
  },
  {
    name: 'estimatedArrival',
    title: 'ETA',
    getCellValue: row =>
      row?.estimatedArrival
        ? `${moment(row?.estimatedArrival).format(dateFormatPattern())}`
        : '-',
  },
  {
    name: 'tenant_role',
    title: 'Role',
    getCellValue: row =>
      row.role.map(item => {
        getType(item)
        return (
          <img
            style={{ width: '22px', marginRight: '10px' }}
            src={getType(item)}
          />
        )
      }),
  },
]
