import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import TableHead from '../../../../../Reusable/TableHead'
import Scrollable from '../../../../../Reusable/Scrollable'
import TableBody from '../../../../../Reusable/TableBody'
import Container from '../../../../../Reusable/Container'
import Loading from '../../../../../Order/Tabs/Loading'
import routes from '../../../../../../routes/route_contants'

const columns = [
  {
    label: 'Name',
    value: e => e?.name,
    minWidth: 180,
  },
  {
    label: 'UN Number',
    value: e => {
      return e?.unNumber
    },
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'ERP/TMS Number(s)',
    value: e => e?.stringMatchers?.join(', ') || '-',
  },
]

function Products({ apiResult, partnerId }) {
  const history = useHistory()
  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data?.results}
            rowOnClick={row =>
              history.push(`${routes.CONNECTION_PRODUCT_DETAILS}/${row.id}`, {
                partnerId,
              })
            }
          />
        )}
      </Scrollable>
    </Container>
  )
}

Products.propTypes = {
  apiResult: PropTypes.object,
}

Products.defaultProps = {
  apiResult: {},
}

export default Products
