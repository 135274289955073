import React from 'react'
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import warning from '../../../assets/warning.svg'
import notice from '../../../assets/notice.svg'
import { CustomTooltip } from './styledComponents/CustomTooltip'

export const CellComponent = props => {
  const isFirstColumn = props.state.columnOrder[0] === props.column.name
  const { hasIssues, issues } = props.row

  if (isFirstColumn && hasIssues) {
    const noticeIssues = issues.filter(i => i.type === 'notice')
    const warningIssues = issues.filter(i => i.type === 'warning')

    return (
      <VirtualTable.Cell {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {warningIssues.length > 0 && (
            <CustomTooltip
              title={`Bol No.: ${warningIssues[0].bolNo}${warningIssues.map(
                i => ` ${i.text}`
              )}`}
              arrow
              warning
            >
              <img
                src={warning}
                alt="warning"
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
              />
            </CustomTooltip>
          )}
          {noticeIssues.length > 0 && (
            <CustomTooltip
              title={`Bol No.: ${noticeIssues[0].bolNo}${noticeIssues.map(
                i => ` ${i.text}`
              )}`}
              arrow
              warning={false}
            >
              <img
                src={notice}
                alt="notice"
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
              />
            </CustomTooltip>
          )}
          {props.value}
        </div>
      </VirtualTable.Cell>
    )
  }
  return <VirtualTable.Cell {...props}>{props.value}</VirtualTable.Cell>
}
