/* eslint-disable react/no-multi-comp */
import {
  Box,
  Button,
  Card,
  Dialog,
  ListItemText,
  Typography,
  TextField,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import tenantAPI from '../../../api/tenant'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import LoadingIndicator from '../../Order/Tabs/Loading'
import { PaletteColors } from '../../../config/colors'
import '../../Super/Tenants/tenantdetails.scss'
import routes from '../../../routes/route_contants'
import useAPILocal from '../../../hooks/useAPILocal'
import { formatContactNo, formatLocation } from '../../../helpers/util'
import { Stack } from '@mui/material'

const fields = [
  { label: 'Tenant Name', value: e => e?.tenant_name || '-' },
  { label: 'Tenant Slug', value: e => e?.slug || '-' },
  { label: 'Tenant EIN', value: e => e?.tenant_ein || '-' },
  {
    label: 'Location',
    value: e =>
      (e?.primary_location !== null && formatLocation(e?.primary_location)) ||
      '-',
  },
  {
    label: 'Invited On',
    value: e =>
      e?.created_on ? moment(e.created_on).format('MMM Do, YYYY') : '-',
  },
  {
    label: 'Contact Number',
    value: e => {
      let formatted = e?.tenant_phone
      if (e?.tenant_phone?.length > 10)
        formatted = e?.tenant_phone?.replaceAll('-', '')
      return formatContactNo(formatted) || '-'
    },
  },
  { label: 'Status', value: e => (e?.data?.is_active ? 'Active' : 'Inactive') },
]

export default function TenantDetails(props) {
  const tenantInfo = useAPILocal(tenantAPI.tenantDetails)
  const tenantUpdateStatus = useAPILocal(tenantAPI.updateTenantStatus)
  const deleteTenant = useAPILocal(tenantAPI.deleteTenant)

  const history = useHistory()
  const tenant_id = history.location.state
  const params = useParams()

  const [active, setActive] = React.useState(true)
  const [open, Delete] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [comment, setComment] = React.useState('')
  const [showCommentBox, setShowCommentBox] = React.useState(false)
  const [isUploading, setIsUploading] = React.useState(false)
  const [image, setImage] = React.useState()

  const handleClose = () => {
    setShowCommentBox(false)
    Delete(false)
  }

  const handleAgree = () => {
    setShowCommentBox(true)
  }

  const handleDisAgree = () => {
    setActive(prev => !prev)
    handleClose()
  }

  useEffect(() => {
    setComment(tenantInfo?.data?.comment)
  }, [tenantInfo?.data?.comment])

  useEffect(() => {
    setActive(tenantInfo?.data?.is_active)
  }, [tenantInfo?.data?.is_active])

  const handleSubmit = () => {
    setIsUploading(true)
    tenantUpdateStatus
      .request(
        {
          comment,
          activeStatus: active,
        },
        tenant_id
      )
      .then(res => {
        setIsUploading(false)
        handleClose()
      })
      .catch(e => {
        alert('Error')
      })
  }
  const handleChangeComment = e => {
    setComment(e.target.value)
  }

  const handleChange = event => {
    setActive(prev => !prev)
    Delete(true)
  }
  const fetchtenantDetails = async () => {
    if (!params?.tenant_id) return
    const data = await tenantInfo.request({
      tenantId: params?.tenant_id,
    })
  }

  useEffect(() => {
    fetchtenantDetails()
  }, [tenantUpdateStatus.isResolved])

  const Item = ({ label, value, toggle, right = false, ...rest }) => {
    return (
      <Box>
        <Box>
          <Typography
            style={{ fontSize: 14, color: PaletteColors.textMedium }}
            variant="caption"
          >
            {label}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              style={{ fontSize: 16, color: PaletteColors.textDark }}
              variant="body1"
            >
              {value}
            </Typography>
            {toggle && (
              <Switch
                color="primary"
                checked={tenantInfo?.data?.is_active}
                onChange={handleChange}
                name="is_active"
              />
            )}
          </Stack>
        </Box>
      </Box>
    )
  }

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}></PageHeading>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageTitle backButton title={tenantInfo?.data?.tenant_name} />
          <div>
            <Button
              variant="outlined"
              color="primary"
              style={{
                color: 'red',
                borderColor: 'red',
                marginRight: '10px',
              }}
              onClick={() => setOpenDelete(true)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push({
                  pathname: `${routes.EDIT_TENANT_SUPERUSER}`,
                  state: {
                    data: tenantInfo?.data,
                  },
                })
              }
            >
              Edit Details
            </Button>
            {openDelete && (
              <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to delete this tenant ?
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setOpenDelete(false)} color="primary">
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      deleteTenant.request(params?.tenant_id)
                        .then(() => {
                          toast.success('Tenant deleted successfully')
                          setOpenDelete(false)
                          // history.push('/tenants')
                        })
                        .catch(error => {
                          toast.error(error?.clientMessage || error?.message || 'Error')
                        })
                    }}
                    color="primary"
                  >
                    {deleteTenant.isPending ? <LoadingIndicator /> : 'Yes'}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </div>
      </Fixed>
      <Scrollable p={3}>
        <Box
          className="shipments-list"
          style={{
            padding: 30,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          <div className="tenantdetailsbox">
            <div className="tenantdetailsboxin">
              {tenantInfo.isPending ? (
                <LoadingIndicator m={4} />
              ) : (
                fields.map(field =>
                  field.label === 'Status' ? (
                    <Box key={field.label}>
                      <Item
                        label={field.label}
                        value={field.value(tenantInfo)}
                        toggle={!tenantInfo.isPending && params?.tenant_id}
                      />
                    </Box>
                  ) : (
                    <Box key={field.label}>
                      <Item
                        label={field.label}
                        value={field.value(tenantInfo?.data)}
                      />
                    </Box>
                  )
                )
              )}

              {/* {!tenantInfo.isPending && params?.tenant_id && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={tenantInfo?.data?.is_active}
                        onChange={handleChange}
                        name="is_active"
                      />
                    }
                  />
                </FormGroup>
              )} */}

              {open && (
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    Attempting to change tenant status
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {tenantInfo?.data?.is_active &&
                        "You are trying to change user status, from active to inactive, this will remove the user from the system and won't be accessible on any place"}
                      {!tenantInfo?.data?.is_active &&
                        'You are trying to change user status, from inactive to active'}
                    </DialogContentText>
                    {showCommentBox && (
                      <TextField
                        onChange={e => handleChangeComment(e)}
                        id="filled-error"
                        value={comment}
                        label="Comment"
                        required
                        placeholder={
                          tenantInfo?.data?.is_active
                            ? 'Comment For Inactivating user'
                            : 'Comment for activating user'
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </DialogContent>
                  {!showCommentBox && (
                    <DialogActions>
                      <Button onClick={handleDisAgree} color="primary">
                        Disagree
                      </Button>
                      <Button onClick={handleAgree} color="primary">
                        Agree
                      </Button>
                    </DialogActions>
                  )}

                  {showCommentBox && (
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button
                        onClick={() => handleSubmit()}
                        color="primary"
                        variant="contained"
                        disabled={!comment}
                      >
                        {isUploading ? 'Updating...' : 'Submit'}
                      </Button>
                    </DialogActions>
                  )}
                </Dialog>
              )}
            </div>

            <div style={{ flex: '20%' }}>
              <img
                src={`data:image/jpeg;base64,${tenantInfo?.data?.tenant_logo}`}
                alt=""
                className="tenantlogo"
                onError={() =>
                  setImage('http://cdn.onlinewebfonts.com/svg/img_242130.png')
                }
              />
            </div>
          </div>
        </Box>
      </Scrollable>
    </Container>
  )
}
