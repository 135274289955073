import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { PaletteColors } from '../../../../config/colors'
import Container from '../../../Reusable/Container'
import Scrollable from '../../../Reusable/Scrollable'
import useStateValue from '../../../../hooks/useStateValue'
import { formatContactNo } from '../../../../helpers/util'
import Fixed from '../../../Reusable/Fixed'
import PageHeading from '../../../Reusable/PageHeading'
import PageTitle from '../../../Reusable/PageTitle'
import useAPILocal from '../../../../hooks/useAPILocal'
import connectionsAPI from '../../../../api/connections'
import Loading from '../../../Loading'
import { toast } from 'react-toastify'

const fields = [
  {
    label: 'Requested By',
    value: e =>
      e?.requested_by?.first_name + ' ' + e?.requested_by?.last_name || '',
    minWidth: 180,
  },
  {
    label: 'E-Mail',
    value: e => e?.requested_by?.email,
    minWidth: 180,
  },
  {
    label: 'Contact No.',
    value: e => formatContactNo(e?.requested_by?.phone_no),
  },
  {
    label: 'Date & Time',
    value: e =>
      moment(e?.requested_on).format('llll').slice(0, e?.requested_on.length),
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'Message',
    value: e => e?.note || '-',
  },
]
const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function ConnectionRequestDetailPage() {
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const connectionData = history?.location.state.data
  const [state] = useStateValue()
  const currentUser = state.user.user_info.user_id
  const [connectionState, setConnectionState] = useState()
  const acceptPartnership = useAPILocal(connectionsAPI.acceptPartnership)
  const rejectPartnership = useAPILocal(connectionsAPI.rejectPartnership)
  const deletePartnership = useAPILocal(connectionsAPI.deletePartnership)

  const accept = () => {
    setConnectionState('accept')
    setOpen(true)
  }

  const reject = () => {
    setConnectionState('reject')
    setOpen(true)
  }

  const deleteReq = () => {
    setConnectionState('delete')
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAgree = () => {
    if (connectionState === 'accept') {
      acceptPartnership.request(connectionData.id)
        .then(() => {
          toast.success('Connection request accepted')
          history.goBack()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
      handleClose()
    }
    if (connectionState === 'reject') {
      rejectPartnership.request(connectionData.id)
        .then(() => {
          toast.success('Connection request rejected')
          history.goBack()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
      handleClose()
    }
    if (connectionState === 'delete') {
      deletePartnership.request(connectionData.id)
        .then(() => {
          toast.success('Connection request deleted')
          history.goBack()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
      handleClose()
    }
  }
  const handleDisAgree = () => {
    setOpen(false)
  }

  return (
    <Container pt={3}>
      {acceptPartnership.isPending || rejectPartnership.isPending ? (
        <Loading />
      ) : (
        <>
          <Fixed px={3}>
            <PageHeading mb={2}>
              <PageTitle
                backButton
                title={connectionData?.tenant?.tenant_name}
              />
            </PageHeading>
          </Fixed>
          <Scrollable p={3}>
            <Box
              className="shipments-list"
              style={{
                padding: 30,
                backgroundColor: PaletteColors.backgroundPrimary,
                borderRadius: 9,
              }}
            >
              <div className="mycompanybox">
                <div className="mycompanyboxin" style={{ gap: '20px' }}>
                  {fields.map(field => (
                    <Box key={field.label}>
                      <Item
                        label={field.label}
                        value={field.value(connectionData)}
                      />
                    </Box>
                  ))}
                </div>
                {connectionData?.requested_by?.user_id !== currentUser ? (
                  <div
                    style={{
                      flex: '20%',
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '10px',
                      alignSelf: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={accept}
                    >
                      Accept Connection Request
                    </Button>
                    <Button variant="outlined" color="primary" onClick={reject}>
                      Reject Connection Request
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      flex: '20%',
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '10px',
                      alignSelf: 'center',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={deleteReq}
                    >
                      Delete Request
                    </Button>
                  </div>
                )}
              </div>
            </Box>
          </Scrollable>
        </>
      )}

      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {connectionState[0]?.toUpperCase() +
              connectionState.slice(1, connectionState.length) || ''}{' '}
            Connection Request
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {connectionState === 'delete'
                ? 'Are you sure you want to delete the request?'
                : `Are you sure you want to ${connectionState} connection request from ${connectionData?.tenant?.tenant_name} ?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleDisAgree} variant="outlined" color="primary">
              No
            </Button>
            <Button onClick={handleAgree} variant="contained" color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  )
}
export default ConnectionRequestDetailPage
