import { styled, Button } from '@mui/material'
import { classes } from './utils'
export const StyledButton = styled(Button)(() => ({
  [`&.${classes.button}`]: {
    fontSize: '14px',
    paddingLeft: '8px',
    paddingRight: '8px',
    height: '32px',
  },
}))
