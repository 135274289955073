import React, { useEffect, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import Modal from '../../../../Modal'
import Connection from '../../../../../assets/connection.svg'
import useForm from '../../../../../hooks/useForm'
import TextInput from '../../../../Reusable/Widgets/TextInput'
import useAPILocal from '../../../../../hooks/useAPILocal'
import connectionsAPI from '../../../../../api/connections'
import { toast } from 'react-toastify';

function ConnectionModal({ isOpen, toggleModal, tenantId, privateConnection }) {
  const connectionRequest = useAPILocal(connectionsAPI.requestPartnership)
  const init = {
    note: '',
  }

  const validationSchema = {
    note: ['required'],
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit } = useForm(
    init,
    validationSchema,
    async result => {
      resetForm()
      await connectionRequest.request({
        note: result.note,
        connectionToken: result.connectionToken || '',
        tenantId: tenantId || '',
      })
        .then(res => {
          toast.success('Connection request sent')
          toggleModal()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
    }
  )

  return (
    <>
      <Modal
        visible={isOpen}
        onClose={toggleModal}
        style={{ padding: '0px 20px 20px 20px' }}
        title={
          privateConnection
            ? 'Connection Request - Private'
            : 'Connection Request'
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={Connection} alt="" style={{ width: '150px' }} />
        </div>
        {privateConnection && (
          <TextInput
            placeholder="Enter connection string provided by partner"
            label="Connection String"
            onChange={e => {
              return handleOnChange({ name: 'connectionToken', value: e })
            }}
            error={errors.connectionToken}
            value={values.connectionToken}
            disabled={false}
            name="connectionToken"
            id="connectionToken"
          />
        )}
        <TextInput
          placeholder="Write reason of your connection request for approving tenant"
          label="Message"
          onChange={e => {
            return handleOnChange({ name: 'note', value: e })
          }}
          error={errors.note}
          value={values.note}
          disabled={false}
          name="note"
          multiline
          id="note"
        />

        <Button
          style={{
            marginTop: '20px',
            marginRight: '15px',
            width: '100%',
          }}
          variant="contained"
          color="primary"
          disabled={connectionRequest.isPending}
          onClick={handleSubmit}
        >
          Send Connection Request
        </Button>
      </Modal>
    </>
  )
}

export default ConnectionModal
