import React from 'react'
import { TableCell } from '@mui/material'
import SelectInput from '../../../component/Reusable/Widgets/SelectInput'

export const DropdownFilter = ({ columnName, filter, onFilter }) => {
  let options = []
  if (columnName === 'tenant_role') {
    options = [
      { value: 'shipper', label: 'Shipper' },
      { value: 'receiver', label: 'Receiver' },
      { value: 'carrier', label: 'Carrier' },
    ]
  } else if (columnName === 'status') {
    options = [
      { value: 'PRE_SHIPMENT', label: 'Pre-Shipment' },
      { value: 'ARRIVED_TO_LOAD', label: 'Arrived to load' },
      { value: 'ARRIVED_TO_UNLOAD', label: 'Arrived to unload' },
      { value: 'IN_TRANSIT', label: 'In Transit' },
      { value: 'COMPLETED', label: 'Completed' },
    ]
  }
  return (
    <TableCell sx={{ padding: 0 }}>
      <SelectInput
        noLabel={true}
        options={options}
        onChange={e => {
          onFilter({ value: e })
        }}
        value={filter?.value ? filter?.value : ''}
      />
    </TableCell>
  )
}
